import React from 'react'
import './ComponentsStyle/ReusableComponents.css'
import robot from '../images/onsiterobot.png'
import { Parallax } from 'react-scroll-parallax'
import DemoPanel from '../images/DemoPanel.svg'
import RobotPNG from '../images/RobotPNG.png'
import { BookDemo, DemoPanels, DemoRobot } from './Assets'

export function BookDemoTile() {
    return <div className='UniversalOuterWrapper DemotilePArentContainer'>
        <div className="UniversalInnerContainer demoTileContent">
            <h1 className="LandingHeading">Want an <span style={{ color: 'var(--PrimaryGreen)' }}>On-Site</span> Demo?</h1>
            <p className="universal-paragraphs">
                Confused about the product? Want to check the capabilities of the Robot? Book a demo now for your plant
            </p>
            <button className="universal-button" onClick={() => { window.open(BookDemo) }}>Book a demo</button>
        </div>

        <Parallax className='DemoRobot' translateX={['0px', '-300px']}><img src={DemoRobot} alt="robot" /></Parallax>
        <img src={DemoPanels} className='DemoPanel' alt="robot" />
    </div>
}

export function Loader() {
    return <div>
        {/* icdsbh */}
        <div className="loader"></div>
    </div>
}

export function UniversalLoader() {
    return <div className='UniversalLoaderWrapper'>
        loading
    </div>
}