import React, { useRef } from "react";
import './ComponentsStyle/benefits.css';
import { Parallax } from "react-scroll-parallax";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

export default function BenefitsPage() {

  const GuestPlantCapacity = useRef()
  const navigate = useNavigate()

  const GetCustomBenefits = () => {

    if (!isNaN(GuestPlantCapacity.current.value) && GuestPlantCapacity.current.value !== "" && GuestPlantCapacity.current.value !== 0 && GuestPlantCapacity.current.value > 0) {
      navigate('/benefits', {
        state: {
          PlantCap: GuestPlantCapacity.current.value
        }
      })
    }
    else {
      // alert("Invalid input")
      toast.error("Invalid Input")
    }
  }
  // explore the benefits number counter animation
  const FireCounters = () => {
    const counters = document.querySelectorAll(".targetCounter");
    counters.forEach((counter) => {
      counter.innerText = "0";
      const updateCounter = () => {
        const target = +counter.getAttribute("data-target");
        const c = +counter.innerText;
        const increment = target / 100;
        if (c < target) {
          // counter.innerText = c + increment;
          counter.innerText = `${Math.ceil(c + increment)}`;
          // console.log(counter.innerText);
          setTimeout(updateCounter, 5);
        } else {
          counter.innerText = target;
        }
      };
      updateCounter();
    });
  }
  // autoscroll function for mobile devices
  // const scrollToSection = () => {
  //   window.scrollTo({
  //     top: document.getElementById("benefits").offsetTop + 800,
  //     left: 0,
  //     behavior: 'smooth'
  //   });
  // };


  return (
    <>
      <div id="benefits" className="benefitsWrapper">
        <h1 className="LandingHeading">So, What are the <span style={{ color: "var(--PrimaryGreen)" }}>Benefits</span> <br className="breakpoint" />of Robotic Cleaning?</h1>
        <p className="universal-paragraphs">
        Robotic cleaning on a <b className="HyperLinks" onClick={() => { navigate('/benefits', { state: { PlantCap: 100 }})}}> 100 MWs</b> plant provides exceptional benefits, increasing efficiency and lowering costs.
        </p>
        <Parallax onEnter={FireCounters}>
          <div></div>
        </Parallax>

        <div className="benefitsCardsWrapper">
          <div className="benefitCardRow">
            <div className="benefitCard c1">
              <h1 className="LandingHeading targetCounter" data-target={240000}>&nbsp;</h1>
              <p className="universal-paragraphs">Gallons of Water saved every month</p>
            </div>
            <div className="benefitCard c2">
              <h1 className="LandingHeading targetCounter" data-target={1624}>&nbsp;</h1>
              <p className="universal-paragraphs">MWs of production loss saved every month</p>
            </div>
          </div>
          <div className="benefitCardRow">
            <div className="benefitCard c3">
              <h1 className="LandingHeading targetCounter" data-target={40.59}>&nbsp;</h1>
              <p className="universal-paragraphs">Rs of money saved every month</p>
            </div>
            <div className="benefitCard c4">
              <h1 className="LandingHeading targetCounter" data-target={253}>&nbsp;</h1>
              <p className="universal-paragraphs">Hours of Labour every month</p>
            </div>
          </div>
        </div>
      </div>

      <div className="GuestBenefitsContentContainer">
        <div className="GuestBenefitsWrapper">
          <br className="breakpoint" />
          <h1 className="LandingHeading">Discover the benefits of <span className="HyperLinks" onClick={() => { navigate("/dhruvaan", { state: { scrollTarget: "DetailedDhruvaanHome" } }) }} style={{ color: "var(--PrimaryGreen)" }}>Dhruvaan</span> Robotic<br className="breakpoint" /> Cleaning for Your plant?</h1>
          <p className="universal-paragraphs">
            Just enter your plant capacity (in MWs) below and get complete benefits of dhruvaan on your plant.
          </p>
          {/* <br className="breakpoint" /> */}
          <div className="GuestBenefitsForm">
            <input type="text" className="universalInput" ref={GuestPlantCapacity} placeholder="Your Plant's Capacity" />
            <button className="universal-button" onClick={GetCustomBenefits}>Get Benefits</button>
          </div>
        </div>
      </div>
    </>
  )
}


// discover the benefits of Dhruvaan Robotic Cleaning for your plant