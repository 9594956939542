import React, { useEffect, useState } from 'react'
import './ComponentsStyle/DetailedDhruvaan.css'
import Navbar from './Navbar'
import './ComponentsStyle/Software.css'
import MobileApp from '../images/MobileApp.png'
import { Parallax, ParallaxBanner } from 'react-scroll-parallax'
import { BookDemoTile } from './ReusableComponents'
import Contact from './Contact'
import Footer from './Footer'
import { DetailedDhruvaanLandingBanner, DhruvaanCurrentVideo } from './Assets'
import { useLocation } from 'react-router-dom'
import { FakeLoader, scrollToSection } from './UniversalFunctions'
import Wheel from '../images/SoftWheelsIcon.png'

export default function DetailedDhruvaan() {
  // Code peice for scrolling to a target section of the page.
  const TargetSection = useLocation()
  useEffect(() => {
    if (TargetSection.state !== undefined && TargetSection.state !== null) {
      scrollToSection(`${TargetSection.state.scrollTarget}`)
    }
  }, [TargetSection])

  // Fake loading
  const [FakeLoading, setFakeLoading] = useState(true)
  useEffect(() => { setTimeout(() => { setFakeLoading(false) }, 600); }, [FakeLoading])
  return (
    <>
      {FakeLoading ? <FakeLoader /> : ""}
      <Navbar isHome={false} />
      <div className='UniversalOuterWrapper DDCustomStyling' style={{ background: `url("${DetailedDhruvaanLandingBanner}") no-repeat center/cover`, backgroundPositionX: "left" }} id='DetailedDhruvaanHome'>
        <div className="UniversalInnerContainer DDinnerCustomStyling">
          <h1 className="LandingHeading">Dhruvaan</h1>
          <p className="universal-paragraphs">
            Dhruvaan is a smart cleaning solution designed to clean the solar panels quickly and efficiently, without causing any damage to the surface of the panels.
          </p>
          <button className="universal-button" onClick={() => { window.open(DhruvaanCurrentVideo) }}>Watch Video</button>
        </div>
      </div>

      <br />
      <br />
      <br />

      {/* detail about brush */}
      <div className="UniversalOuterWrapper">
        <div className="UniversalInnerContainer">
          <h1 className="LandingHeading">An Innovative Brush for <span style={{ color: 'var(--PrimaryGreen)' }}>Effective</span> Cleaning</h1>
          <p className="universal-paragraphs">
            Experience spotless panels with our innovative brush. Its microfiber cloth ensures thorough cleaning, contributing to maximize the energy production
          </p>
          <br />
          <FeatureList

            Features={[
              {
                Name: 'Ultimate Cleaning with 400+ Rpm Brush',
                Desc: "Our 400+ RPM rotating brush cleans panels very efficiently and leaves panels spotless.",
                Icon: "fas fa-sync"
              },
              {
                Name: 'High GSM microfiber cloth',
                Desc: "Our robot's brush is made of soft and gentle microfiber cloth that leaves panels scratchless and healthy",
                Icon: "fas fa-feather-alt"
              },
              {
                Name: 'Smart Airflow Technology',
                Desc: "Our high-speed revolving brush generates forward airflow, removing dust particles and resulting in scratch-free cleaning.",
                Icon: "fas fa-wind"
              },

            ]}

          />
          <br />
        </div>
      </div>


      {/* relliablity section */}
      <ParallaxBanner className='UniversalOuterWrapper' layers={[{ image: "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/reliable_CA-M_sGJS.png?updatedAt=1694848437766", speed: -10 }]}>
        <div className="UniversalInnerContainer FeatureBanner">
          <h1 className="LandingHeading">Most Reliable, hence Safe for panels</h1>
          <p className="universal-paragraphs">
            Dhruvaan is exceptionally dependable. Strong performance is guaranteed by its lightweight construction, soft wheels, and 4x4 driving. You can rely on smooth functioning and long-lasting effectiveness.
          </p>
        </div>
      </ParallaxBanner>


      {/* realiblity features */}
      <div className="UniversalOuterWrapper">
        <div className="UniversalInnerContainer">
          <br />
          <FeatureList
            Features={[
              {
                Name: `Lightweight design provides convenience.`,
                Desc: "The light weight of Dhruvaan make it more reliable and suitable for panels.",
                Icon: "fas fa-feather-alt"
              },
              {
                Name: ' 4 x 4 Driving Mechanism',
                Desc: "The All wheels driving system and high torque motors allow the robot to climb up to 25 degree slanted panels.",
                Icon: "fas fa-cog"
              },
              {
                Name: 'Soft wheels for Healthy panels',
                Desc: "Dhruvaan has soft wheels that allow for easy moving without damaging the delicate surface of solar panels.",
                Icon: "none",
                image: Wheel
              },

            ]}

          />
          <br />
        </div>
      </div>



      {/* Smaart feature section */}
      <ParallaxBanner className='UniversalOuterWrapper' layers={[{ image: "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/SmartFeatures__7tuo1Bcf.png?updatedAt=1694848437357", speed: -14 }]}>
        <div className="UniversalInnerContainer FeatureBanner">
          <h1 className="LandingHeading">The Adaptive Performance and Advanced Technology of Dhruvaan</h1>
          <p className="universal-paragraphs">
            "Dhruvaan's intelligence shines through adaptable operations in a wide range of environments." Its innovative failure detection and self-alignment functions makes it unstoppable in every robust condition.
          </p>
        </div>
      </ParallaxBanner>


      {/* smart features */}
      <div className="UniversalOuterWrapper">
        <div className="UniversalInnerContainer">
          <br />
          <FeatureList
            Features={[
              {
                Name: 'Smart Self-Alignment feature',
                Desc: "Dhruvaan's self-alignment feature always maintains it on track, resulting in better efficiency and smooth operations.",
                Icon: "fas fa-align-center"
              },
              {
                Name: 'Advanced failure detection technology',
                Desc: "Advanced failure detection technology auto detects potential problems in the robot and alerts software for immediate correction.",
                Icon: "fas fa-exclamation-circle"
              },
              {
                Name: 'Decision making capabilities',
                Desc: "Dhruvaan can decide whether to operate in various situations, such as bad weather, part failures, and so on.",
                Icon: "fas fa-microchip"
              },

            ]}

          />
          <br />
        </div>
      </div>



      {/* mobile app section */}
      <div className="UniversalOuterWrapper MAsection">
        <div className="UniversalInnerContainer">
          <h1 className="LandingHeading"><span style={{ color: 'var(--PrimaryGreen)' }}>Dhruvaan </span> Software</h1>
          <p className="universal-paragraphs">
            Experience seamless control and insights with our IOT-integrated software. Manage robots globally, access health reports, and unlock advanced features effortlessly.
          </p>
          <br />
          <div className="ButtonsContainer">
            <button className="universal-button" onClick={() => { window.open("https://play.google.com/store/apps/details?id=com.vysion.yuvaan") }}>
              <img src="https://ik.imagekit.io/hgfugmm0dbi/Group_1064-1_JQJ-W3Fa8h.png?ik-sdk-version=javascript-1.4.3&updatedAt=1651923932655" alt="solar robot" className="GooglePlayImg" />
            </button>
            <button className="universal-button" onClick={() => { window.open("https://yuvaan-307e0.web.app/#/") }}>
              <img src="https://ik.imagekit.io/hgfugmm0dbi/Group_1064_BnSTFPn969.png?ik-sdk-version=javascript-1.4.3&updatedAt=1651923932821" alt="dhruva robot" className="WebAppImg" />
            </button>
          </div>
          <Parallax speed={10}><img src={MobileApp} className='ma' alt="dhruvaan" /></Parallax>
        </div>
      </div>

      {/* mobile app  features */}
      <div className="UniversalOuterWrapper">
        <div className="UniversalInnerContainer">
          <br />
          <FeatureList
            Features={[
              {
                Name: 'Real Time Cleaning reports',
                Desc: "Monitor your plant's cleaning in real time through our IOT integrated software",
                Icon: "far fa-history"
              },
              {
                Name: 'Remotely robots controlling',
                Desc: "Seamlessly control robots from anywhere around the world via the Dhruvaan Software.",
                Icon: "far fa-mobile-alt"
              },
              {
                Name: 'Online Health monitoring and fault detection',
                Desc: "Dhruvaan always does a health assessment before cleaning to guarantee it is healthy and sends all findings to software.",
                Icon: "fas fa-briefcase-medical"
              },

            ]}
          />
          <br />
        </div>
      </div>


      {/* demo tile */}
      <BookDemoTile />
      <br />
      <Contact />
      <br />
      <br />
      <Footer />

    </>
  )
}



function FeatureList(props) {
  return <div className="SoftwareFeaturesContainer">
    {
      props.Features.map((feature) => {
        return <div className="SoftwareFeatureCard">
          <div className="FeatureUpperSection">
            <div className="FeatureIcon">
              {feature.Icon === "none" ? <img src={feature.image} /> : <i className={feature.Icon}></i>}
            </div>
            <h1 className="SmallHeadings">{feature.Name}</h1>
          </div>
          <p className="universal-paragraphs">{feature.Desc} </p>
        </div>
      })
    }
  </div>
}