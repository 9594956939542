import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export default function ImageViewer() {
    const location = useLocation()
    const navigate = useNavigate()
    return (
        <div className='onPhoto' onClick={() => { navigate("/clients", { state: { scrollTarget: "OurCutouts" } }) }}>
            <img src={location.state.image} data-aos="zoom-in" data-aos-duration="200" className='image' alt="" />
        </div>
    )
}
