import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import SwiperCore, { Autoplay } from 'swiper';
import './ComponentsStyle/patent.css';
import { Parallax } from 'react-scroll-parallax';
import { useMediaQuery } from 'react-responsive';
import { Brookfeild, CE, FrameFive, FrameFour, FrameOne, FrameThree, FrameTwo, IAF, IIIT, ISO, IntellectualPI, ONGC, SSIP, StartupIndia, iStart } from './Assets';
import { Link, useNavigate } from 'react-router-dom';


export default function Patent() {

  SwiperCore.use([Autoplay])
  const navigate = useNavigate()
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 992px)' })
  const isTablet = useMediaQuery({ query: '(min-width: 760px)' })
  const isMobile = useMediaQuery({ query: '(min-width: 550px)' })

  return (
    <div className="PatentSectionParentContainer" id='patents' >
      <div className="PatentContentWrapper">
        <div>
          <h1 className="LandingHeading">Patented Technology</h1>
          <h1 className="LandingHeading">ISO & CE Certified</h1>
          <p className="universal-paragraphs">
            Vysion Technology’s roots lies in the innovation. Our first and foremost focus is always towards building great products through deep research and development.
          </p>
          <div className="universal-button" onClick={() => { navigate('/clients', { state: { scrollTarget: "ClientsHome" } }) }}>Clients & Achievements</div>
        </div>

        <div className="framesWrapper">
          <Parallax speed={2}><img src={FrameOne} className='frame f1' alt="vision robot" /></Parallax>
          <Parallax speed={4}><img src={FrameTwo} className='frame f2' alt="dhruvan solar" /></Parallax>
          <Parallax speed={6}><img src={FrameThree} className='frame f3' alt="solar panel cleaning" /></Parallax>
          <Parallax speed={4}><img src={FrameFour} className='frame f4' alt="solar dhuva cleaning" /></Parallax>
          <Parallax speed={2}><img src={FrameFive} className='frame f5' alt="visen technology" /></Parallax>
        </div>
      </div>



      <div className='PatentContentWrapper'>
        <Swiper
          modules={[Autoplay]}
          className="LogosSwiper"
          // spaceBetween={40}
          slidesPerView={isDesktopOrLaptop ? 7 : isTablet ? 5 : isMobile ? 4 : 4}
          autoplay={{ delay: 1000, disableOnInteraction: false }}
          loop = {true}
        >
          <SwiperSlide><div className='SwiperCard'><img src={IntellectualPI} alt=" robot" /></div></SwiperSlide>
          <SwiperSlide><div className='SwiperCard'><img src={Brookfeild} alt="Dhruvaan solar robot" /></div></SwiperSlide>
          <SwiperSlide><div className='SwiperCard'><img src={ISO} alt="Dhruvaan solar robot" /></div></SwiperSlide>
          <SwiperSlide><div className='SwiperCard'><img src={ONGC} alt="robotic solar panel cleaning" /></div></SwiperSlide>
          <SwiperSlide><div className='SwiperCard iaf'><img src={IAF} alt="Dhruvaan solar robot" /></div></SwiperSlide>
          <SwiperSlide><div className='SwiperCard'><img src={StartupIndia} alt="Dhruvaan solar robot" /></div></SwiperSlide>
          <SwiperSlide><div className='SwiperCard'><img src={IIIT} alt="Claning" /></div></SwiperSlide>
          <SwiperSlide><div className='SwiperCard'><img src={SSIP} alt="Solar Panel" /></div></SwiperSlide>
          <SwiperSlide><div className='SwiperCard'><img src={iStart} alt="Solar energy" /></div></SwiperSlide>
          <SwiperSlide><div className='SwiperCard'><img src={CE} alt="Dhruvaan solar robot" /></div></SwiperSlide>
        </Swiper>
      </div>
    </div>
  )
}