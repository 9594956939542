import React, { useEffect, useMemo, useRef, useState } from 'react'
import './ComponentsStyle/DetailedBenefit.css'
import './ComponentsStyle/benefits.css';
import Navbar from './Navbar'
import LineChart from './LineChart';
import { useLocation } from 'react-router-dom';
import Footer from './Footer'
import { toast } from 'react-hot-toast';
import { CalculatingWaterSaved, FakeLoader, calculateProductionLoss, generateUserData } from './UniversalFunctions';

export default function DetailedBenefits() {

    // Getting entered plant capacity on main page
    const location = useLocation()

    // scrolling function for scrolling to a specific location on the page
    const scroll = (key) => {
        window.scrollTo({
            top: document.getElementById(key).offsetTop,
            left: 0,
            behavior: 'smooth'
        });
    };


    // function for updating the counters
    const FireCounters = () => {
        const counters = document.querySelectorAll(".targetCounter");
        counters.forEach((counter) => {
            counter.innerText = "0";
            const updateCounter = () => {
                const target = +counter.getAttribute("data-target");
                const c = +counter.innerText;
                const increment = target / 100;
                if (c < target) {
                    counter.innerText = `${Math.ceil(c + increment)}`;
                    setTimeout(updateCounter, 5);
                } else {
                    counter.innerText = target;
                }
            };
            updateCounter();
        });
    }

    const [UserDataStore, setUserDataStore] = useState([])

    // variables to be send in LineChart.js component
    const SolingRef = useRef(0.8) //For taking values from user / Default value is 0.8
    const PlantCapacity = useRef(100) //For taking Plant capacity from user / Default value is 100
    const WaterCleaningPerMonthRef = useRef(2) //For taking Water cleaning cycles from user / Default value is 2

    // variables for advanced options
    const [ShowAdvancedOptions, setShowAdvancedOptions] = useState(false)
    const ModuleCapacityRef = useRef(550) //For taking panel capacity from user / default value is 550 MWs
    const TarrifPlanRef = useRef(2.5) //For taking Tarrif Plan from user / Default value is 2.5rs

    // default values for calculations
    const defaultValues = {
        WaterCleaningLoss: 0,
        RoboticCleaningLoss: {
            TenCycles: 0,
            ThirtyCycles: 0
        },
        LabourSecondsToClean1Panel: 5,
        EnteredPlantCapacity: location.state?.PlantCap === undefined ? 100 : location.state?.PlantCap,
        EnteredPlantCapacityMinMax: {
            Min: 1,
            Max: 1000
        },
        EnteredSoilingRate: 0.8,
        EnteredSolingRateMinMax: {
            Min: 0.1,
            Max: 3.2
        },
        EnteredWaterCleaningCycles: 2,
        EnteredWaterCleaningCycleMinMax: {
            Min: 0,
            Max: 16
        },
        EnteredModuleCapacity: 550,
        EnteredModuleCapacityMinMax: {
            Min: 1,
            Max: 600
        },
        EnteredTarrifPlan: 2.5,
        EnteredTarrifPlanMinMax: {
            Min: 0.1,
            Max: 20
        },
        PreviousSolingRate: 0,
        PreviousWaterCleaningCycles: 0,
    }
    const [CardsValues, setCardsValues] = useState({
        WaterSavingValue: 0,
        ProductionLossSavingValue: {
            TenCycles: 0,
            ThirtyCycles: 0
        },
        MoneySavingValue: 0,
        LabourHoursSaving: 0,
    })

    const ResetBorder = (eRef) => { eRef.current.style.borderBottom = "1px solid var(--PrimaryGreen)" }
    const [Variables, setVariables] = useState(defaultValues)
    const handleInputChange = (field, value, ref) => {

        // set value to default value if user leaves any field empty and removes everything
        var newValue = value === '' || value === undefined ? defaultValues[field] : value;

        if (field === "EnteredPlantCapacity") {
            if (value < defaultValues.EnteredPlantCapacityMinMax.Min || value >= defaultValues.EnteredPlantCapacityMinMax.Max) {
                newValue = defaultValues[field]
                setVariables({ ...Variables, [field]: newValue, });
                toast.error(`Plant capacity must be between ${defaultValues.EnteredPlantCapacityMinMax.Min} and ${defaultValues.EnteredPlantCapacityMinMax.Max}`)
                PlantCapacity.current.style.borderBottom = "2px solid red"
            }
            else {
                newValue = value
                setVariables({ ...Variables, [field]: newValue, });
                PlantCapacity.current.style.borderBottom = "1px solid var(--PrimaryGreen)"
            }
        }
        else if (field === 'EnteredSoilingRate') {
            if (value < defaultValues.EnteredSolingRateMinMax.Min || value > defaultValues.EnteredSolingRateMinMax.Max) {
                newValue = defaultValues[field]
                setVariables({ ...Variables, [field]: newValue, });
                toast.error(`Soling rate must be between ${defaultValues.EnteredSolingRateMinMax.Min} and ${defaultValues.EnteredSolingRateMinMax.Max}`)
                SolingRef.current.style.borderBottom = "2px solid red"
            }
            else {
                newValue = value
                setVariables({ ...Variables, [field]: newValue, });
                SolingRef.current.style.borderBottom = "1px solid var(--PrimaryGreen)"
            }
        }
        else if (field === 'EnteredWaterCleaningCycles') {
            if (value === "" || value < defaultValues.EnteredWaterCleaningCycleMinMax.Min || value >= defaultValues.EnteredWaterCleaningCycleMinMax.Max) {
                newValue = defaultValues[field]
                setVariables({ ...Variables, [field]: newValue });
                toast.error(`Water cleaning cycles must be between ${defaultValues.EnteredWaterCleaningCycleMinMax.Min} and ${defaultValues.EnteredWaterCleaningCycleMinMax.Max}`)
                WaterCleaningPerMonthRef.current.style.borderBottom = "2px solid red"
            }
            else {
                newValue = value
                setVariables({ ...Variables, [field]: newValue });
                WaterCleaningPerMonthRef.current.style.borderBottom = "1px solid var(--PrimaryGreen)"
            }
        }
        else if (field === 'EnteredModuleCapacity') {
            if (value < defaultValues.EnteredModuleCapacityMinMax.Min || value >= defaultValues.EnteredModuleCapacityMinMax.Max) {
                toast.error(`Module capacity must be between ${defaultValues.EnteredModuleCapacityMinMax.Min} and ${defaultValues.EnteredModuleCapacityMinMax.Max}`)
                newValue = defaultValues[field]
                setVariables({ ...Variables, [field]: newValue, });
                ModuleCapacityRef.current.style.borderBottom = "2px solid red"
            }
            else {
                newValue = value
                setVariables({ ...Variables, [field]: newValue, });
                ModuleCapacityRef.current.style.borderBottom = "1px solid var(--PrimaryGreen)"
            }
        }
        else if (field === 'EnteredTarrifPlan') {
            if (value < defaultValues.EnteredTarrifPlanMinMax.Min || value >= defaultValues.EnteredTarrifPlanMinMax.Max) {
                newValue = defaultValues[field]
                setVariables({ ...Variables, [field]: newValue, });
                TarrifPlanRef.current.style.borderBottom = "2px solid red"
                toast.error(`Tarrif Plan must be between ${defaultValues.EnteredTarrifPlanMinMax.Min} and ${defaultValues.EnteredTarrifPlanMinMax.Max}`)
            }
            else {
                newValue = value
                setVariables({ ...Variables, [field]: newValue, });
                TarrifPlanRef.current.style.borderBottom = "1px solid var(--PrimaryGreen)"
            }
        }
        else { }



        // if user removed everything after typing then reset the border
        if (value === '') { ResetBorder(ref) }
        // Update the state with the new value
        setVariables({ ...Variables, [field]: newValue, });
    };

    console.log('plant cap :' + location.state?.PlantCap)
    // firing counters at the page render
    useEffect(() => {
        FireCounters()
    }, [CardsValues])


    useEffect(() => {
        scroll("DetailedBenefitTop")
        const UserData = generateUserData(Variables.EnteredSoilingRate, Variables.EnteredWaterCleaningCycles)
        setUserDataStore(UserData)
        setCardsValues({
            ...CardsValues,
            ProductionLossSavingValue: { ThirtyCycles: Math.round(calculateProductionLoss(UserData, Variables.EnteredPlantCapacity, Variables.EnteredTarrifPlan)[0]) },
            WaterSavingValue: Math.round(CalculatingWaterSaved(Variables.EnteredPlantCapacity, Variables.EnteredModuleCapacity, Variables.LabourSecondsToClean1Panel, Variables.EnteredWaterCleaningCycles)[0]),
            LabourHoursSaving: Math.round(CalculatingWaterSaved(Variables.EnteredPlantCapacity, Variables.EnteredModuleCapacity, Variables.LabourSecondsToClean1Panel, Variables.EnteredWaterCleaningCycles)[1]),
            MoneySavingValue: calculateProductionLoss(UserData, Variables.EnteredPlantCapacity, Variables.EnteredTarrifPlan)[1].toFixed(2)
        })
    }, [])


    // function to be run manually
    function CalcBenefitsCustomized() {
        const UserData = generateUserData(Variables.EnteredSoilingRate, Variables.EnteredWaterCleaningCycles)
        setUserDataStore(UserData)
        setCardsValues({
            ...CardsValues,
            ProductionLossSavingValue: { ThirtyCycles: Math.round(calculateProductionLoss(UserData, Variables.EnteredPlantCapacity, Variables.EnteredTarrifPlan)[0]) },
            WaterSavingValue: Math.round(CalculatingWaterSaved(Variables.EnteredPlantCapacity, Variables.EnteredModuleCapacity, Variables.LabourSecondsToClean1Panel, Variables.EnteredWaterCleaningCycles)[0]),
            LabourHoursSaving: Math.round(CalculatingWaterSaved(Variables.EnteredPlantCapacity, Variables.EnteredModuleCapacity, Variables.LabourSecondsToClean1Panel, Variables.EnteredWaterCleaningCycles)[1]),
            MoneySavingValue: calculateProductionLoss(UserData, Variables.EnteredPlantCapacity, Variables.EnteredTarrifPlan)[1].toFixed(2)
        })
    }

    const [FakeLoading, setFakeLoading] = useState(true)
    useEffect(() => { setTimeout(() => { setFakeLoading(false) }, 600); }, [FakeLoading])

    return (
        <>
            {FakeLoading ? <FakeLoader /> : ""}

            <div className="DetailedBenefitContentParentWrpper" id="DetailedBenefitTop">
                <Navbar isHome={false} />
                <div className="DetailedBenefitContentWrapper">
                    {/* <button onClick={CheckValues}>Check</button> */}
                    <h1 className="LandingHeading">So, What are the <span style={{ color: 'var(--darkgreen)' }}>benefits</span> <br />of Robotic Cleaning?</h1>
                    <p className="universal-paragraphs">
                        Here is the benefits for a <b>{Variables.EnteredPlantCapacity} MWs</b> plant. You can modify details for getting more detailed benefits.
                    </p>

                    <div className="TakeDetailedInformation">
                        <div className='DefaultValueContainer'>
                            <input type="number" className="universalInput" ref={PlantCapacity} placeholder='Plant Capacity (MW)' onChange={(e) => { handleInputChange("EnteredPlantCapacity", e.target.value, PlantCapacity) }} />
                            <span>Current Value :  <b>{`${Variables.EnteredPlantCapacity} MW`}</b></span>
                        </div>
                        <div className="DefaultValueContainer">
                            <input type="number" className="universalInput" ref={SolingRef} onChange={(e) => { handleInputChange("EnteredSoilingRate", e.target.value, SolingRef) }} placeholder='Power loss due to Soiling (%)' />
                            <span>Current Value :  <b>{`${Variables.EnteredSoilingRate}%`}</b></span>
                        </div>
                        <div className="DefaultValueContainer">
                            <input type="number" className="universalInput" ref={WaterCleaningPerMonthRef} onChange={(e) => { handleInputChange("EnteredWaterCleaningCycles", e.target.value, WaterCleaningPerMonthRef) }} placeholder='Water Cleanings per month' />
                            <span>Current Value :  <b>{`${Variables.EnteredWaterCleaningCycles} Times`}</b></span>
                        </div>
                    </div>
                    {
                        ShowAdvancedOptions ? <div className="TakeDetailedInformation">
                            <div className='DefaultValueContainer'>
                                <input type="number" className="universalInput" ref={ModuleCapacityRef} onChange={(e) => { handleInputChange("EnteredModuleCapacity", e.target.value, ModuleCapacityRef) }} placeholder='Module Capacity' />
                                <span>Current Value :  <b>{`${Variables.EnteredModuleCapacity} Watts`}</b></span>
                            </div>
                            <div className='DefaultValueContainer'>
                                <input type="number" className="universalInput" ref={TarrifPlanRef} onChange={(e) => { handleInputChange("EnteredTarrifPlan", e.target.value, TarrifPlanRef) }} placeholder='Your tarrif plan' />
                                <span>Current Value :  <b>{`₹ ${Variables.EnteredTarrifPlan}`}</b></span>
                            </div>
                        </div> : ''
                    }
                    <p className="HyperLinks" style={{ fontFamily: 'Poppins', marginLeft: '10px', marginTop: '10px', color: 'grey' }} onClick={() => { setShowAdvancedOptions(!ShowAdvancedOptions) }}>Advanced Options</p>
                    <button className="universal-button" onClick={CalcBenefitsCustomized}>Modify benefits</button>


                    <div className="benefitsCardsWrapper">
                        <div className="benefitCardRow">
                            <div className="benefitCard c1">
                                <h1 className="LandingHeading targetCounter" data-target={CardsValues.WaterSavingValue}></h1>
                                <p className="universal-paragraphs">Gallons of Water Saved Every Month</p>
                            </div>
                            <div className="benefitCard c2">
                                <h1 className="LandingHeading targetCounter" data-target={CardsValues.ProductionLossSavingValue.ThirtyCycles}></h1>
                                <p className="universal-paragraphs">MWs of Production loss Saved every Month</p>
                            </div>
                        </div>
                        <div className="benefitCardRow">
                            <div className="benefitCard c3">
                                <h1 className="LandingHeading targetCounter" data-target={CardsValues.MoneySavingValue}></h1>
                                <p className="universal-paragraphs">Lakh rupees of Money saved every month </p>
                            </div>
                            <div className="benefitCard c4">
                                <h1 className="LandingHeading targetCounter" data-target={CardsValues.LabourHoursSaving}></h1>
                                <p className="universal-paragraphs">Hours of Labour saved every month</p>
                            </div>
                        </div>
                    </div>
                    <br />

                    <br />
                    <br />
                    <h1 className="LandingHeading">Why Robotic Cleaning is  <span style={{ color: 'var(--darkgreen)' }}>better</span><br /> than Water Cleaning?  </h1>
                    <p className="universal-paragraphs">
                        Robotic Cleaning on a <b> 100 MWs</b> plant gives a lot of benefits. Just checkout these benefits.
                    </p>
                </div>


                <div className='chart'>
                    <LineChart
                        Variables={Variables}
                        setVariables={setVariables}

                        UserData={UserDataStore}
                    />
                </div>
                <Footer />

            </div>
        </>
    )
}
