import React, { useEffect, useState } from 'react';
import style from './ComponentsStyle/Landingpage.module.css';
import { BookDemo } from './Assets';
import { FakeLoader } from './UniversalFunctions';
export default function LandingPage() {

  useEffect(() => {
    var video = document.getElementById('video');
    video.muted = true;
    video.play();
  }, [])

  const [VideoLoader, setVideoLoader] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setVideoLoader(false)
    }, 10000);
  }, [])

  // Fake loading
  const [FakeLoading, setFakeLoading] = useState(true)
  useEffect(() => { setTimeout(() => { setFakeLoading(false) }, 600); }, [FakeLoading])

  return (
    <>
      {FakeLoading ? <FakeLoader /> : ""}
      <section className={style.first_page} id='home'>
        {
          VideoLoader ? <div className="LandingVideoBufferLoader">
            <span class="loader"></span>
          </div> : ""
        }
        <video id="video" loop autoPlay muted playsInline >
          <source src="https://ik.imagekit.io/hgfugmm0dbi/BGvideo%20(1)_bf0ODJFSW.mp4?updatedAt=1694504376628" />
        </video>
        <div className={style.text_wrapper}>
          <h1 className='LandingHeading' style={{ color: "white", textTransform: "uppercase" }}>The &nbsp;future&nbsp; of&nbsp; Solar&nbsp; panel cleaning&nbsp; for&nbsp; a&nbsp; sustainable&nbsp; world</h1>
          <p className='universal-paragraphs' style={{ color: "white" }}><b>Dhruvaan</b> is a smart internet connected solar panels cleaning robot with the daily cleaning efficiency of more than <b>3.8%</b>.</p>
          {/* DemoVideo_button is styled in app.css */}
          <div style={{ display: "flex" }}>
            <div className='universal-button' onClick={() => { window.open(BookDemo) }}>
              Book a demo
            </div>
          </div>
        </div>
      </section>
    </>
  )
}