import React, { useEffect, useLayoutEffect, useState } from 'react'
import Navbar from './Navbar'
import './ComponentsStyle/DetailedAbout.css'
import { Parallax, ParallaxBanner } from 'react-scroll-parallax'

import mission from '../images/Target.svg';
import vision from '../images/eye.svg';
import value from '../images/value.svg';
import Contact from './Contact'
import Footer from './Footer'
import Patent from './Patent'
import { FakeLoader, scrollToSection } from './UniversalFunctions'
import { useLocation } from 'react-router-dom'
import { Amit, CE, ISO, Kunal, Mayank, Nikhil, Nishant, Pratyush, VysionGreenLogo } from './Assets'

export default function DetailedAbout() {

    // Code peice for scrolling to a target section of the page.
    const TargetSection = useLocation()
    useEffect(() => {
        if (TargetSection.state !== undefined && TargetSection.state !== null) {
            scrollToSection(`${TargetSection.state.scrollTarget}`)
        }
    }, [TargetSection])

    // Fake loading
    const [FakeLoading, setFakeLoading] = useState(true)
    useEffect(() => { setTimeout(() => { setFakeLoading(false) }, 600); }, [FakeLoading])

    return (
        <>
            {FakeLoading ? <FakeLoader /> : ""}

            <div className='DetailedAboutWrapper' id='DetailedAboutHome'>

                <Navbar isHome={false} />
                <ParallaxBanner layers={[{ image: "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/lan_Kgao29tcqo.png?updatedAt=1694848323122", speed: -5 }]} className="AboutLandingBanner">
                    <img src={VysionGreenLogo} alt="vysion" />
                </ParallaxBanner>

                <div className="DetailedAboutCompany" id='AboutCompany'>
                    <h1 className="LandingHeading">Vysion Greenbots Pvt. Ltd.</h1>
                    <p className="universal-paragraphs">
                        Vysion Greenbots Pvt. Ltd. is an innovative technology startup based in Jodhpur, specializing in the development of cutting-edge solutions for the solar panel cleaning industry. Our company comprises a dedicated team of young and skilled enthusiasts hailing from prestigious institutions such as IITs, NITs, and IIITs. We are driven by a common vision to revolutionize the renewable energy sector by providing sustainable, cost-effective, and hassle-free cleaning solutions through robotics.
                    </p>
                    <p className="universal-paragraphs">
                        Together, with our clients and partners, we strive to build a cleaner, greener, and brighter future for all.
                    </p>

                </div>

                <div className='panel' id='MissionVision'>
                    <div className="page-section">
                        <div className="container">

                            <div className="vision" data-aos="fade-up" data-aos-duration="300">
                                <img className="microAnimations" autoPlay src={vision} alt="vision" />
                                <h1 className='LandingHeading'><span style={{ fontWeight: '500' }}>Our</span> Vision</h1>
                                <p className='universal-paragraphs'>To innovate in the technology driven industries that demand immediate attention & tirelessly strive until we come up with smarter solutions.</p>
                            </div>
                            <div className="vision" data-aos="fade-up" data-aos-duration="400" >
                                <img className="microAnimations" src={mission} alt="vision technology" />

                                <h1 className='LandingHeading'><span style={{ fontWeight: '500' }}>Our</span> Mission</h1>
                                <p className='universal-paragraphs'>To deliver technical solutions most smartly for complex problems that defy India in becoming the Global Leader.</p>
                            </div>
                            <div className="vision" data-aos="fade-up" data-aos-duration="600">
                                <img className="microAnimations" src={value} alt="dhruvan" />

                                <h1 className='LandingHeading'><span style={{ fontWeight: '500' }}>Our</span> Values</h1>
                                <p className='universal-paragraphs'>To create best high tech products which will help to solve complicated problems and to provide best user experience.</p>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="CertificationDecorationCard">
                    <div className="CertificationContentcontainer">
                        <h1 className="LandingHeading">CE & ISO Certified</h1>
                        <p className="universal-paragraphs">
                            Vysion Greenbots Pvt. Ltd. is an innovative technology startup based in Jodhpur, specializing in the development of cutting-edge solutions
                        </p>
                    </div>

                    <Parallax speed={3} className='ISOLogoParallax'>
                        <img src={ISO} alt="dhruvaan" />
                    </Parallax>
                    <Parallax speed={3} className='CELogoParallax'>
                        <img src={CE} alt="dhruv solar" />
                    </Parallax>
                </div>


                <div className="teamSection" id='OurTeam'>
                    <h1 className='LandingHeading'>Our Team</h1>
                    <p className="universal-paragraphs">
                        The team comprises of young and skilled enthusiasts from IITs, NITs, and IIITs innovating tirelessly in different domains like IoT, Artificial Intelligence, Mobile and Web Development, Robotics, Embedded Systems, Cloud, and so on.
                    </p>

                    <div className="teamMembersWrapper">

                        <Card
                            image={Nikhil}
                            name={"Nikhil Vyas"}
                            linkedinUrl={"https://www.linkedin.com/in/nikhil-vyas-4a1a81148/"}
                            instaUrl={"https://www.instagram.com/nik_vyas_95/"}
                            twitterUrl={"https://twitter.com/Nikhilvyas14"}
                            role={"Founder & CEO"} />

                        <Card
                            image={Pratyush}
                            name={"Pratyush Gupta"}
                            linkedinUrl={"https://www.linkedin.com/in/pratyush-gupta-917328192/"}
                            instaUrl={"https://www.instagram.com/pratyushgupta190/"}
                            twitterUrl={"https://twitter.com/Pratyus35377059"}
                            role={"Co-Founder & CTO"} />

                        <Card
                            image={Mayank}
                            name={"Mayank Arora"}
                            linkedinUrl={"https://www.linkedin.com/in/mayank-arora-b78060131"}
                            instaUrl={"https://www.instagram.com/mayank_arora_29/"}
                            twitterUrl={"https://twitter.com/mayanka58776610"}
                            role={"Electronics Reserch Head"} />

                        <Card
                            image={Kunal}
                            name={"Kunal Sharma"}
                            linkedinUrl={"https://www.linkedin.com/in/kunal-sharma-441125185/"}
                            instaUrl={"https://www.instagram.com/_co.nfused/"}
                            twitterUrl={"https://twitter.com/Kunal7203"}
                            role={"Product Head"} />

                        <Card
                            image={Amit}
                            name={"Amit Verma"}
                            linkedinUrl={"https://www.linkedin.com/in/amit-verma-2a46971b4/"}
                            instaUrl={"https://www.instagram.com/it_is_amit/"}
                            twitterUrl={"https://twitter.com/it_is_amit"}
                            role={"Web Development Head"} />

                        <Card
                            image={Nishant}
                            linkedinUrl={"https://www.linkedin.com/in/nishant-20/"}
                            instaUrl={"https://www.instagram.com/nishant._oo7/"}
                            twitterUrl={"https://twitter.com/iam__oo7"}
                            name={"Nishant Singh"}
                            role={"Design Head"} />
                    </div>
                </div>



                {/* contact section */}
                <br />
                <br />
                <Patent />
                <br />
                <br />
                <br />
                <Contact />
                <br />
                <br />
                <Footer />
            </div>

        </>
    )
}


function Card(props) {
    return <div className='myCard'>
        <div class="innerCard">
            <div className="MemberDP">
                <img src={props.image} alt="dhruvaan" />
            </div>
            <h1 className="SmallHeadings">{props.name}</h1>
            <p className="universal-paragraphs memberDesignation">{props.role}</p>
            <div className="MemberSocialLinks">
                <div className="socialBox" onClick={() => window.open(props.linkedinUrl)}>
                    <i class="fab fa-linkedin-in" style={{ color: '#474747' }}></i>
                </div>
                <div className="socialBox" onClick={() => window.open(props.twitterUrl)}>
                    <i class="fab fa-twitter" style={{ color: '#474747' }}></i>
                </div>
                <div className="socialBox" onClick={() => window.open(props.instaUrl)}>
                    <i class="fab fa-instagram" style={{ color: '#474747' }}></i>
                </div>
            </div>
        </div>
    </div>
}