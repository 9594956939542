import RobotPNG from '../images/RobotPNG.png'
import DemoPanel from '../images/DemoPanel.svg'


// Complete images, icons and videos links here 
// Landing Page

// Dhruvaan Collage
export const CollageTileVideoOne = "https://ik.imagekit.io/hgfugmm0dbi/bg_video_compressed_hnvyd8csk3.mp4?updatedAt=1688798206288" //Video
export const CollageTileVideoTwo = "https://ik.imagekit.io/hgfugmm0dbi/Bgvideo_li1p34qNo.mp4?updatedAt=1694423908096" //Video
export const CollageTileOne = "https://ik.imagekit.io/hgfugmm0dbi/bg_video_compressed_hnvyd8csk3.mp4?updatedAt=1688798206288" //Video
export const CollageTileTwo = "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/lowwidlh_ndx84G0AW.png?updatedAt=1694443120490" //Photo
export const CollageTileThree = "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/20230805_171213_lmc_8%201_4sjaVC9hj.png?updatedAt=1694506129179" // Photo
export const CollageTileFour = "https://ik.imagekit.io/hgfugmm0dbi/bg_video_compressed_hnvyd8csk3.mp4?updatedAt=1688798206288" // Video

//Book a Demo Section
export const DemoPanels = DemoPanel
export const DemoRobot = RobotPNG

// Patent section assets
export const FrameOne = "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/f1_A52Yv7I3OQ.png?updatedAt=1692339540119"
export const FrameTwo = "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/f2_EwDIrwxnlR.png?updatedAt=1692339540203"
export const FrameThree = "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/f3_YoFsv1RwJt.png?updatedAt=1692339540230"
export const FrameFour = "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/f4_E8E5V3gvPg.png?updatedAt=1692339540282"
export const FrameFive = "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/f5_2CuuGwCeH.png?updatedAt=1692339543525"

// Clients and certifications companies logo
export const IntellectualPI = "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/inrellectualpropertyindia_pPK5ky0dGc.png?updatedAt=1692339543613"
export const Brookfeild = "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/brookfeild_RIpdMlR2fo.png?updatedAt=1692339540031"
export const ISO = "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/iso_tWYh6m1yEA.png?updatedAt=1692339543837"
export const ONGC = "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/ongc_rJ9p3F7VaC.png?updatedAt=1692339544119"
export const IAF = "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/iaf_UqtiF3hVGr.png?updatedAt=1692339543622"
export const StartupIndia = "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/startupindia_Myu6cMBFu.png?updatedAt=1692339547085"
export const IIIT = "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/iiit_coPBoUQd8.png?updatedAt=1692339543571"
export const SSIP = "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/ssip_jzvmyi1Xv.png?updatedAt=1692339546697"
export const iStart = "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/istart_1-sCfT5aa.png?updatedAt=1692340208272"
export const CE = "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/ce_nYLkQJ-hz.png?updatedAt=1692339539962"
export const welspun = "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/Welspun_f7kv-_9PL.png?updatedAt=1697623937133"
export const solarpulse = "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/solarpulse_zYBQLpbGq.png?updatedAt=1697624324603"

// some news and articles logos
export const dainikbhaskar = "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/image%2098_vlcYoQqEv.png?updatedAt=1697631349088"
export const edtimes = "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/61b8bdc46b22d5504d751b69_Ed%20Times%20Logo%202_-QSxm8WhM.png?updatedAt=1697631237904"
export const cutout1 = "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/cutout_rQjPRM9At.png?updatedAt=1697632655303"
export const cutout2 = "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/IMG_20231018_180944_449%201_IGVqBYBNY.png?updatedAt=1697632916475"
export const cutout3 = "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/IMG_20231018_180949_187%201_71eC-_1C-.png?updatedAt=1697632915607"



// Detailed Dhruvaan page landing ParallaxBanner
export const DetailedDhruvaanLandingBanner = "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/DhruvaanPAgeLandingBG_N8ECFnnr7o.png?updatedAt=1692339540330"
export const ClientsLandingBanner = "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/Client_IufGkroqU?updatedAt=1699004274788"

// Our team profile images
export const Nikhil = "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/nikhil_DdZ9ZI-uql.png?updatedAt=1692339543874"
export const Amit = "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/amit_dY-XG1Yn8.png?updatedAt=1692339540078"
export const Pratyush = "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/prat_PBlBf39rHW.png?updatedAt=1692339544187"
export const Nishant = "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/nishant_wfQkVtYfPA.png?updatedAt=1692339544085"
export const Mayank = "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/mayank_if_-aGcf9.png?updatedAt=1692339543580"
export const Kunal = "https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/Kunal%20Dp%202_1oYFCHgRk.png?updatedAt=1694600938824"

//All external links here
export const BookDemo = "https://demo.dhruvaan.com/";
export const DhruvaanWebApp = "https://yuvaan-307e0.web.app/#/";
export const DhruvaanMobileApp = "https://play.google.com/store/apps/details?id=com.vysion.yuvaan";
export const Instagram = "https://www.instagram.com/vysion_dhruvaan";
export const Facebook = "https://www.facebook.com/vysiontech95";
export const YouTube = "https://www.youtube.com/@vysiontechnology4434";
export const DhruvaanProductionVideo = "";
export const DhruvaanCurrentVideo = "https://www.youtube.com/watch?v=OfYIRkPuHVA";
export const Linkedin = "https://www.linkedin.com/company/vysion-greenbots/mycompany";
export const Twitter = "https://twitter.com/vysion_tech";
export const TechvysionWebsite = "https://techvysion.com/";
export const DeconWebsite = "https://demo.techvysion.com/";

// Company Information
export const ContactNumber = "+91 80786 93503";
export const Email = "admin@techvysion.com";
export const OfficeAddress = "Plot No. 6, Street Number 11, M. I. A, Phase- II, Basni, Jodhpur - 342005, Rajasthan, India";
export const OfficeGoogleMapsLink = "https://goo.gl/maps/TPRmNbv4KbRbmLWf7";
export const AllRightsReserved = "Copyright © Vysion Greenbots Pvt. Ltd. 2023 | All right reserved";


// Vysion Greenbots Logo
export const VysionGreenLogo = 'https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/companyLogo_E9Ekt3oQtb.png?updatedAt=1692339540108'