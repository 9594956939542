import React from "react";
import './ComponentsStyle/Intropage.css';
import { Link, useNavigate } from "react-router-dom";

export default function IntroPage() {

  const navigate = useNavigate()

  return (
    <div className="home" id="yuvaan">
      <div className="homeContainerCustomCss">
        <div className="box">
          <h1 className="LandingHeading">Dhruvaan</h1>
          <p className="universal-paragraphs">
            <b>Dhruvaan</b> is a smart and robust robot connected with the seamless potential of IoT and the Cloud. The Robot aims at efficient cleaning of commercial solar panels to increase renewable solar energy production at the lowest costs.
          </p>
          <br />
          <Link className="universal-button" to="/dhruvaan" state={{ scrollTarget: "DetailedDhruvaanHome"}}>Know more</Link>
        </div>


        <div className="box ShiftUp">
          <p className="universal-paragraphs">A Product by</p>
          <h1 className="LandingHeading">Vysion Greenbots</h1>
          <p className="universal-paragraphs alignRight">
            Vysion Technology is a technology startup incubated by the Gujarat government under the SSIP initiative. We deliver a series of smart solutions in different sectors where the Nation is still lagging to establish it's feet.
          </p>
          <br />
          <Link className="universal-button" to="/about" state={{ scrollTarget: "DetailedAboutHome"}}>Know more</Link>


          {/* <div className={style.buttonwr}>
            <a className="universal-button" href="https://www.techvysion.com/" target="_blank" rel="noreferrer" >
              Know more
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
}
