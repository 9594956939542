import "./App.css";
import LandingPage from "./components/LandingPage";
import IntroPage from "./components/IntroPage";
import DeviceParts from "./components/DeviceParts";
import AppPage from "./components/AppPage";
import BenefitsPage from "./components/BenefitsPage";
import Patent from "./components/Patent";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { ParallaxProvider } from 'react-scroll-parallax';
import { Route, Routes, useLocation } from "react-router-dom";
import DetailedBenefits from "./components/DetailedBenefits";
import DetailedAbout from "./components/DetailedAbout";
import DetailedDhruvaan from "./components/DetailedDhruvaan";
import { BookDemoTile, Loader } from "./components/ReusableComponents";
import Clients from "./components/Clients";
import { Toaster } from "react-hot-toast";
import { scrollToSection } from "./components/UniversalFunctions";
import ImageViewer from "./components/ImageViewer";


function App() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  // Code peice for scrolling to a target section of the page.
  const TargetSection = useLocation()
  useEffect(() => {
    // console.log("app js target section "+ TargetSection.state);
    if (TargetSection.state !== undefined && TargetSection.state !== null && TargetSection.state.scrollTarget !== undefined) {
      scrollToSection(`${TargetSection.state.scrollTarget}`)
    }
  }, [TargetSection])




  return (
    <>
      <ParallaxProvider>
        <Routes>
          <Route path="/" element={
            <div className="App">
              <Navbar isHome={true} />
              <LandingPage />
              <IntroPage /><br /><br /><br />
              <DeviceParts /><br /><br /><br />
              <BenefitsPage /><br />
              <AppPage /><br />
              <BookDemoTile /><br />
              <Patent /><br /><br /><br />
              <Contact /><br /><br /><br />
              <Footer />
            </div>
          } />
          <Route path="/benefits" element={<DetailedBenefits />} />
          <Route path="/about" element={<DetailedAbout />} />
          <Route path="/dhruvaan" element={<DetailedDhruvaan />} />
          <Route path="/clients" element={<Clients isDev={false} />} />
          <Route path="/img" element={<ImageViewer />} />
        </Routes>
      </ParallaxProvider>
      <div><Toaster position="top-center" toastOptions={{ duration: 2000 }} /></div>
    </>
  );
}

export default App;