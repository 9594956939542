import React from "react";
import './ComponentsStyle/Software.css';
import Rive from 'rive-react';
import anim from '../images/anim.riv';
import phone from '../images/phone.riv';
import { Parallax } from "react-scroll-parallax";

export default function AppPage(props) {
  return (
    <div className="SoftwareSection" id="application">

      {/* heading div */}
      <div className="HeadingsAndButtonsContainer">
        <div className="HeadingsContainer">
          <h1 className="LandingHeading">Dhruvaan <span style={{ color: 'var(--PrimaryGreen)' }}>D-2</span></h1>
          <p className="universal-paragraphs">
            Experience seamless control and insights with our IOT-integrated software. Manage robots globally, access health reports, and unlock advanced features effortlessly.
          </p>
        </div>
        <div className="ButtonsContainer">
          <button className="universal-button" onClick={() => { window.open("https://play.google.com/store/apps/details?id=com.vysion.yuvaan") }}>
            <img src="https://ik.imagekit.io/hgfugmm0dbi/Group_1064-1_JQJ-W3Fa8h.png?ik-sdk-version=javascript-1.4.3&updatedAt=1651923932655" alt="Solar panel cleaning" className="GooglePlayImg" />
          </button>
          <button className="universal-button" onClick={() => { window.open("https://yuvaan-307e0.web.app/#/") }}>
            <img src="https://ik.imagekit.io/hgfugmm0dbi/Group_1064_BnSTFPn969.png?ik-sdk-version=javascript-1.4.3&updatedAt=1651923932821" alt="Solar panel cleaning" className="WebAppImg" />
          </button>
        </div>
      </div>

      <div className="SoftwareFeaturesContainer">
        <div className="SoftwareFeatureCard">
          <div className="FeatureUpperSection">
            <div className="FeatureIcon">
              <i className="far fa-history fa-lg"></i>
            </div>
            <h1 className="SmallHeadings">Real Time <br />Cleaning reports</h1>
          </div>
          <p className="universal-paragraphs">Monitor your plant's cleaning in real time through our IOT integrated software</p>
        </div>
        <div className="SoftwareFeatureCard">
          <div className="FeatureUpperSection">
            <div className="FeatureIcon">
              <i className="far fa-mobile-alt fa-lg"></i>
            </div>
            <h1 className="SmallHeadings">Remotely robots <br /> controlling</h1>
          </div>
          <p className="universal-paragraphs">Seamlessly control robots from anywhere around the world via the Dhruvaan<br className="breakpoint" /> Software.</p>
        </div>
        <div className="SoftwareFeatureCard">
          <div className="FeatureUpperSection">
            <div className="FeatureIcon">
              <i className="far fa-briefcase-medical fa-lg"></i>
            </div>
            <h1 className="SmallHeadings">Online Health monitoring<br />and fault detection</h1>
          </div>
          <p className="universal-paragraphs">Dhruvaan always does a health assessment before cleaning to guarantee it is healthy and sends all findings to software. </p>
        </div>
      </div>

      <div className="SoftwareAnimationSection">
        <div className="DecorativeRectangle"></div>
        <Parallax speed={20} translateY={['100px', '-50px']}>
          <div className="AnimBlock">
            <div className="DesktopAnimation">
              <AnimationLaptop />
            </div>
            <div className="MobileAnimation">
              <AnimationMobile />
            </div>
          </div>
        </Parallax>
      </div>
    </div>
  );
}

function AnimationLaptop() {
  return <Rive src={anim} />;
}

function AnimationMobile() {
  return <Rive src={phone} />;
}