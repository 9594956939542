import { useEffect, useState } from "react";

export function ScrollPage(PageID) {
    const sectionElement = document.getElementById(PageID);
    if (sectionElement) {
        // Scroll to the section using smooth behavior
        sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
}

export function scrollToSection(key) {
    if (key !== null && key !== undefined) {
        window.scrollTo({
            top: document.getElementById(key)?.offsetTop - 70,
            left: 0,
            behavior: 'smooth'
        });
    }
};


// function for generating data for graph
export function generateUserData(solingLoss, waterCleaningsPerMonth) {
    const robotCleaningsPerMonth = 10
    const dailyRobotCleanings = 30
    const numberOfDays = 31;
    const waterCleaningInterval = Math.round(30 / waterCleaningsPerMonth);
    const robotCleaningInterval = Math.round(30 / robotCleaningsPerMonth)
    const dailyRobotCleaningInterval = Math.round(30 / dailyRobotCleanings);
    const stepSize = 0.5; // The step size for days (e.g., 0.5, 1, 1.5, 2, ...)
    const UserData = [];
    let waterCleaningLoss = 0;
    let robotCleaningTenCyclesLoss = 0;
    let robotCleaningDailyLoss = 0;
    let currentDay = 0;

    for (let i = 0; i < numberOfDays; i += stepSize) {
        UserData.push({
            id: i + 1,
            Day: currentDay,
            WaterCleaning: Math.round(waterCleaningLoss * 10) / 10,
            RobotCleaningTenCycles: Math.round(robotCleaningTenCyclesLoss * 10) / 10,
            RoboticCleaningDaily: Math.round(robotCleaningDailyLoss * 10) / 10,
        });
        if ((currentDay + 1) % waterCleaningInterval === 0) {
            waterCleaningLoss = 0.3;
        } else {
            waterCleaningLoss += solingLoss / 2; // Adjust soling loss for half-day
        }

        if (currentDay > 0 && (currentDay) % robotCleaningInterval === 0) {
            robotCleaningTenCyclesLoss = 0.5;
        } else {
            robotCleaningTenCyclesLoss += solingLoss / 2; // Adjust soling loss for half-day
        }

        if (currentDay > 0 && (currentDay) % dailyRobotCleaningInterval === 0) {
            robotCleaningDailyLoss = 0.4; // Fixed soling loss for daily robotic cleaning
        } else {
            robotCleaningDailyLoss += solingLoss / 2; // Adjust soling loss for half-day
        }
        currentDay += stepSize;
    }


    console.log("userdata generated", UserData);
    return UserData

    // calculating production loss according to current userdata
    // calculateProductionLoss(UserData)
    // setUserDataStore(UserData)
}



// function for calculating production loss for all three lines AKA calculation of area under the curve.
export function calculateProductionLoss(data, plantCapacity, tarrifPlan) {
    // let plantCapacity = 200
    let waterCleaningLoss = 0;
    let roboticCleaning10CyclesLoss = 0;
    let roboticCleaning30CyclesLoss = 0;
    let ProductionLoss = 0

    for (const entry of data) {
        const day = entry.Day;
        if (Number.isInteger(day)) { // Skip fractional days
            console.log("Running loop");
            const waterCleaningCapacityLoss = ((entry.WaterCleaning * plantCapacity) / 100) * 5; // x5 for 5 hours of daily prodution hours
            const roboticCleaning10CyclesCapacityLoss = ((entry.RobotCleaningTenCycles * plantCapacity) / 100) * 5;  // x5 for 5 hours of daily prodution hours
            const roboticCleaning30CyclesCapacityLoss = ((entry.RoboticCleaningDaily * plantCapacity) / 100) * 5;  // x5 for 5 hours of daily prodution hours

            waterCleaningLoss += waterCleaningCapacityLoss;
            roboticCleaning10CyclesLoss += roboticCleaning10CyclesCapacityLoss;
            roboticCleaning30CyclesLoss += roboticCleaning30CyclesCapacityLoss;
        }
    }

    // calculating production loss
    ProductionLoss = waterCleaningLoss - roboticCleaning30CyclesLoss
    let LossUnits = ProductionLoss * 1000 // Units

    // calculating Money saved in 1 month
    let MoneySaved = LossUnits * tarrifPlan
    let MoneySavedInLakhs = MoneySaved / 100000
    return [ProductionLoss, MoneySavedInLakhs]
}



export function CalculatingWaterSaved(PlantCap, ModuleCap, timeToCleanOnePanel, WaterCleaningCycles) {

    // calculating water consumption from plant capacity    
    let PlantCapacityInWatts = PlantCap * 1000000
    let NumberOfModules = PlantCapacityInWatts / ModuleCap
    let WaterConsumption = NumberOfModules * 5  //Consumption = Number of modules * 5 Liters
    let WaterConsGallon = WaterConsumption * 0.264
    let WaterConsByCleaningCycles = WaterConsGallon * WaterCleaningCycles

    // calculating labour hours
    let LabourSeconds = NumberOfModules * timeToCleanOnePanel
    let LabourHours = LabourSeconds / 3600
    return [WaterConsByCleaningCycles, LabourHours]
}







// calculations

/*
1: water Savings
Plant Capacity = 100 MW
Plant Cap in Watts = 100 x 1000000 Watts
Module Capacity = 550 Watts
Number of Modules = Plant capacity in watts / Module Capacity
Water required for cleaning 1 Panel = 5L
Water required For cleaning plant = 5 x No. of Modules


2: Production loss saving
Calculating Loss of Water cleaning by Taking soiling Loss value everyday for a month, 
and calculating % Capacity of Plant. If Plant capacity is 100MW, and soling loss on
a day is 0.8, Then 0.8% of 100MW is 0.8MW. So water cleaning loss on that that will be 
0.8 x 5 hours = 4MW. Same for All 30 days and adding them will give us Total Production
loss of water cleaning in 30 days.

Same we will find loss for robotic cleaning (30 cycles) in 30 days
and Total Production loss we saved = Loss of Water cleaning - Robotic Cleaning

3: Money savings 
if We're saving 200Mw of Production in 1 month, then 200 x 1000 units = 200000 UNITS OF 
electricity. 
Money savings = 200000 units x 2.5 rs = 5000000 rs

4: Labour Hours Saving
By taking default value of time taken by Labours to clean 1 panel = 5 seconds
Labour time to clean Total number of Panels = 5 x Number of Panels 
*/

export const FakeLoader = () => {
    return <div className="FakeLoaderWrapper">
        <div className="loader"></div>
    </div>
}
