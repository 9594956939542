import React from 'react';
import './ComponentsStyle/Contact.css'
import { ContactNumber, Email, OfficeGoogleMapsLink } from './Assets';

export default function Contact(props) {
  return (
    <>
      <div className="ContactMasterContainer" id='ContactHome'>
        <div id='contact' className='ContactGetInTouchWrapper'>

          <div className="Getintouchcontainer">
            <div>
              <h1 className="LandingHeading">Get in Touch</h1>
              <p className="universal-paragraphs">
              Feel free to get in touch with us. Call us if you have any queries regarding the product or mail us to our mail id.
              </p>
            </div>

            <div className="ContactLinks">
              <lord-icon
                src="https://cdn.lordicon.com/ssvybplt.json"
                trigger="hover"
                colors="primary:#0c9136"
                state="hover-phone-ring"
                style={{ width: '32px', height: '32px' }}>
                <a href={`tel:${ContactNumber}`} className="ContactDetailBox box1">
                  <h1 className="SmallHeadings">Call</h1>
                </a>
              </lord-icon>
              <lord-icon
                src="https://cdn.lordicon.com/diihvcfp.json"
                trigger="hover"
                colors="primary:#ffcb00"
                style={{ width: '32px', height: '32px' }}>
                <a href={`https://mail.google.com/mail/?view=cm&fs=1&to=${Email}`} className="ContactDetailBox box2" target='_blank'>
                 
                
                 
                 
                 
                 
                 
                  <h1 className="SmallHeadings">E-mail</h1>
                </a>
              </lord-icon>
              <lord-icon
                src="https://cdn.lordicon.com/zzcjjxew.json"
                trigger="hover"
                colors="primary:#617cdd,secondary:#617cdd"
                stroke="100"
                style={{ width: '36px', height: '36px' }}>
                <a href={`${OfficeGoogleMapsLink}`} className="ContactDetailBox box3" target='_blank'>
                  <h1 className="SmallHeadings">Visit</h1>
                </a>
              </lord-icon>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
