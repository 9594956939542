import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { Parallax } from 'react-scroll-parallax'
import './ComponentsStyle/DetailedBenefit.css'
import { useMediaQuery } from 'react-responsive'
import { registerables, Chart } from 'chart.js'


export default function LineChart(props) {

    Chart.register(...registerables)
    const isMobile = useMediaQuery({ query: '(min-width: 550px)' })
    const [chartData, setchartData] = useState({ datasets: [], })

    // function for independently set ChartData
    function settingChartdata(UserData) {
        setchartData({
            labels: UserData?.map((data) => data.Day),
            datasets: [
                {
                    label: "RoboticCleaning (30 Cycles)",
                    data: UserData?.map((data) => data.RoboticCleaningDaily),
                    backgroundColor: ["orange"],
                    borderColor: "orange",
                    borderWidth: 2,
                    tension: 0.4,
                },
                {
                    label: "Robotic Cleaning (10 Cycles)",
                    data: UserData?.map((data) => data.RobotCleaningTenCycles),
                    backgroundColor: ["lightgreen"],
                    borderColor: "lightgreen",
                    borderWidth: 2,
                    tension: 0.4,
                },
                {
                    label: "Water Cleaning (2 Cycles)",
                    data: UserData?.map((data) => data.WaterCleaning),
                    backgroundColor: ["skyblue"],
                    borderColor: "skyblue",
                    borderWidth: 2,
                    tension: 0.4,
                },

            ],

        });
    }

    // checking the old values are changes or Notification, if changes are detected refresh the graph otherwise do nothing
    const [SpecificProgress, setSpecificProgress] = useState(false) // True when user scrolls to a specific point. 
    const CheckForNewValues = () => {
        if (SpecificProgress) {
            settingChartdata(props.UserData)
        }
    }

    // // running the changes detection function on page load and SpecificProgress === true
    useEffect(() => {
        CheckForNewValues()
    }, [SpecificProgress])


    return <div>
        <Parallax onProgressChange={(progress) => progress > 0.4 ? setSpecificProgress(true) : setSpecificProgress(false)} className='ChartAnimationTrigger'></Parallax>
        <Line data={chartData} options={{
            maintainAspectRatio: false,
            responsive: true,
            interaction: {
                mode: 'index',
                intersect: false
            },
            elements: {
                point: {
                    radius: isMobile ? 3 : 1
                }
            },
            scales: {
                yAxes: {
                    title: {
                        display: true,
                        text: 'Soiling Loss',
                        font: {
                            size: isMobile ? 16 : 10
                        }
                    }
                },
                xAxes: {
                    title: {
                        display: true,
                        text: "Days",
                        font: {
                            size: isMobile ? 16 : 10
                        }
                    }
                }
            }
        }} />
    </div>
}
