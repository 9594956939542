import React, { useState } from 'react';
import './ComponentsStyle/Footer.css';
import './ComponentsStyle/Contact.css'
import logo from '../images/companyLogo.png'
import { AllRightsReserved, ContactNumber, DeconWebsite, Email, Facebook, Instagram, Linkedin, OfficeAddress, OfficeGoogleMapsLink, TechvysionWebsite, Twitter, YouTube } from './Assets';
import emailjs from '@emailjs/browser';
import { toast } from 'react-hot-toast';
import { Loader } from './ReusableComponents';
import { useNavigate } from 'react-router-dom';

export default function Footer() {

  // Contact form validation
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const navigate = useNavigate()
  const FormValidation = (e) => {
    if (e.target.fullName.value === '') {
      toast.error("Name must not be empty")
      e.target.fullName.focus()
      return false
    }
    else if (e.target.phone.value === '' || e.target.phone.value.length > 13 || e.target.phone.value.length <= 0) {
      toast.error("Please enter a valid phone number")
      e.target.phone.focus()
      return false
    }
    else if (e.target.message.value === '') {
      toast.error("Please enter a message")
      e.target.message.focus()
      return false
    }
    else if (e.target.email.value === '') {
      toast.error("Please enter a email address")
      e.target.email.focus()
      return false
    }
    else if (!emailRegex.test(e.target.email.value)) {
      toast.error("Please enter a valid email address")
      e.target.email.focus()
      return false
    }
    else { return true }
  }

  const [Loading, setLoading] = useState(false)
  // send email function
  const sendEmail = (e) => {
    e.preventDefault();
    const isValid = FormValidation(e)
    if (isValid) {
      setLoading(true)
      emailjs.sendForm('service_00d0u96', 'template_l2mdd8i', e.target, 'X_lrAJW-SL0NZfiwg')
        .then(() => {
          toast.success("Message sent!");
          setLoading(false)
        }, (error) => {
          toast.error(error.text);
          setLoading(false)
        });
      e.target.reset();
    }
  };


  return (
    <section className="footer" id='FooterHome'>
      <div className="FooterBase">

        <div className="FooterCompanyDetails">
          <div className="FooterGrid">
            <div className="div1"><h1 className='SmallHeadings'> <img src={logo} alt="vysion" /></h1></div>
            <div className="div2"><h1 className='SmallHeadings'> Explore</h1></div>
            <div className="div3"><h1 className='SmallHeadings'> Visit</h1></div>
            <div className="div4"><h1 className='SmallHeadings'> E-mail</h1></div>
            <div className="div5"><h1 className='SmallHeadings'> Call Us</h1></div>
            <div className="div6"><h1 className='SmallHeadings'> Other</h1></div>

            <div className="div7"> <p className='universal-paragraphs HyperLinks' onClick={() => { window.open(DeconWebsite) }}>Decon</p></div>
            <div className="div8"> <p className='universal-paragraphs HyperLinks' onClick={() => { window.open() }}>Dhruvaan</p></div>
            <div className="div9"> <p className='universal-paragraphs HyperLinks' onClick={() => { window.open(TechvysionWebsite) }}>Vysion</p></div>
            <div className="div10"><p className='universal-paragraphs' onClick={() => { window.open(OfficeGoogleMapsLink) }}>{OfficeAddress} </p></div>
            <div className="div11"><p className='universal-paragraphs'><a href={`mailto:${Email}`}>{Email}</a> </p></div>
            <div className="div12"><p className='universal-paragraphs'><a href={`tel:${ContactNumber}`} >{ContactNumber}</a></p></div>
            <div className="div13"><p className='universal-paragraphs HyperLinks' onClick={() => { navigate('/clients', { state: { scrollTarget: "OurNews" } }) }}>News </p></div>
            <div className="div14"><p className='universal-paragraphs HyperLinks' onClick={() => { navigate('/clients', { state: { scrollTarget: "OurPatents" } }) }}>Patents </p></div>
            <div className="div15"><p className='universal-paragraphs HyperLinks' onClick={() => { navigate('/clients', { state: { scrollTarget: "OurClientsTestimonials" } }) }}>Clients</p></div>
          </div>




          <div className="socials">
            <a href={Instagram} target="_blank" rel="noreferrer"><i className='fab fa-instagram' id='insta'></i></a>
            <a href={Twitter} target="_blank" rel="noreferrer"><i className="fab fa-twitter" id='twitter'></i></a>
            <a href={Linkedin} target="_blank" rel="noreferrer"><i className="fab fa-linkedin" id='linkedin'></i></a>
            <a href={Facebook} target="_blank" rel="noreferrer"><i className="fab fa-facebook" id='facebook'></i></a>
            <a href={YouTube} target="_blank" rel="noreferrer"><i className="fab fa-youtube" id='Youtube'></i></a>
          </div>
        </div>



        <form className="contactFormWrapper" onSubmit={sendEmail}>
          <h1 className="LandingHeading">Have any queries?<br />Send us here</h1>
          <p className="universal-paragraphs">Send  us if you have any queries regarding the product.</p>

          <div className="inputFeilds">
            <div className="FormDiv1">
              <input type="text" className='universalInput' placeholder='Name' name="fullName" />
            </div>
            <div className="FormDiv2">
              <input type="text" className='universalInput' maxLength="14" placeholder='Phone no. with country code' name="phone" />
            </div>
            <div className="FormDiv3">
              <input type="text" className='universalInput' placeholder='E-mail id' name="email" />
            </div>
            <div className="FormDiv4">
              <textarea type="text" className='universalTextArea' placeholder='Message' name="message" />
            </div>
          </div>
          <button className="universal-button">{Loading ? <span><Loader /></span> : <span>&nbsp; Send &nbsp;</span>}</button>
        </form>
      </div>
      <br />
      <p className="universal-paragraphs RightsReserved">
        {AllRightsReserved}
      </p>
    </section >
  )
}
