import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import './ComponentsStyle/Clients.css'
import { ParallaxBanner } from 'react-scroll-parallax'
import { useMediaQuery } from 'react-responsive';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import Comma from '../images/Testimonial.svg'
import article from '../images/articles.svg'
import { Brookfeild, CE, IAF, IIIT, ISO, IntellectualPI, ONGC, SSIP, StartupIndia, iStart, Amit, solarpulse, welspun, edtimes, dainikbhaskar, cutout1, cutout2, cutout3, ClientsLandingBanner, FrameOne, FrameFive, FrameThree, FrameTwo } from './Assets';
import Footer from './Footer'
import Contact from './Contact';
import { useNavigate } from 'react-router-dom';

import one from '../images/1.png'
import two from '../images/2.png'
import three from '../images/3.png'
import patent from '../images/patent.svg'
import news from '../images/news.svg'
import ClientsMobileBG from '../images/ClientsMobileBG.png'
import { FakeLoader } from './UniversalFunctions';


export default function Clients(props) {
  SwiperCore.use([Autoplay, Pagination])
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 992px)' })
  const isTablet = useMediaQuery({ query: '(min-width: 760px)' })
  const isMobile = useMediaQuery({ query: '(min-width: 550px)' })
  const navigate = useNavigate()

  // enlarging image when user clicks on a cutout image
  const [data, setData] = useState('')
  const ViewImage = (img) => {
    setData(img)
    console.log("here is the sending data : " + img);
  }
  useEffect(() => {
    if (data !== '') {
      navigate('/img', {
        state: {
          image: data
        }
      })
    }
  }, [data])

  // Fake loading
  const [FakeLoading, setFakeLoading] = useState(true)
  useEffect(() => { setTimeout(() => { setFakeLoading(false) }, 600); }, [FakeLoading])





  if (props.isDev) {
    return <div className="PUD">
      <Navbar />
      <h1 className='SmallHeadings'>Page under development</h1>

      {/* divs for not getting id missing errors */}
      <div id="ClientsHome"></div>
      <div id="OurClientsTestimonials"></div>
      <div id="OurNews"></div>
      <div id="OurCutouts"></div>
      <div id="OurPatents"></div>
    </div>
  }

  else {
    return (
    <>
    
      {FakeLoading ? <FakeLoader /> : ""}

      <div className='UniversalOuterWrapper' style={{ overflow: "hidden" }} id='ClientsHome'>

        {/* navbar component */}
        <Navbar isHome={false} />

        {/* LAnding banner */}
        <ParallaxBanner layers={[{ image: ClientsLandingBanner, speed: -20 }]} className="ClientsLandingBanner" id='ClientsHome'>
          <h1 className="LandingHeading">Clients & Achievements</h1>
        </ParallaxBanner>

        {/* logos swiper */}
        <div className='PatentSectionParentContainer' style={{ backgroundColor: "transparent" }}>
          <div className='PatentContentWrapper'>
            <Swiper
              modules={[Autoplay]}
              className="LogosSwiper"
              // spaceBetween={40}
              slidesPerView={isDesktopOrLaptop ? 7 : isTablet ? 5 : isMobile ? 4 : 4}
              autoplay={{ delay: 1000, disableOnInteraction: false }}
              loop={true}
            >
              <SwiperSlide><div className='SwiperCard'><img src={IntellectualPI} alt="Dhruvaan solar robot" /></div></SwiperSlide>
              <SwiperSlide><div className='SwiperCard'><img src={Brookfeild} alt="Dhruvaan solar robot" /></div></SwiperSlide>
              <SwiperSlide><div className='SwiperCard'><img src={ISO} alt="Dhruvaan solar robot" /></div></SwiperSlide>
              <SwiperSlide><div className='SwiperCard'><img src={ONGC} alt="Dhruvaan solar robot" /></div></SwiperSlide>
              <SwiperSlide><div className='SwiperCard iaf'><img src={IAF} alt="Dhruvaan solar robot" /></div></SwiperSlide>
              <SwiperSlide><div className='SwiperCard'><img src={StartupIndia} alt="Dhruvaan solar robot" /></div></SwiperSlide>
              <SwiperSlide><div className='SwiperCard'><img src={IIIT} alt="Dhruvaan solar robot" /></div></SwiperSlide>
              <SwiperSlide><div className='SwiperCard'><img src={SSIP} alt="Dhruvaan solar robot" /></div></SwiperSlide>
              <SwiperSlide><div className='SwiperCard'><img src={iStart} alt="Dhruvaan solar robot" /></div></SwiperSlide>
              <SwiperSlide><div className='SwiperCard'><img src={CE} alt="Dhruvaan solar robot" /></div></SwiperSlide>
            </Swiper>
          </div>
        </div>

        <br />


        {/* Our clients here */}

        <div className="UniversalInnerContainer" id='OurClientsTestimonials'>
          <div className="ClientsTestimonialsWrapper">
            <div className="SectionHeadingandDesc">
              <img src={Comma} alt="Dhruva robot" />
              <h1 className="LandingHeading">What <br className='BreakPoint' />Clients Say</h1>
              <p className="universal-paragraphs">
                Vysion Technology's core values are innovation, with a strong emphasis on producing exceptional products through extensive research and development.
              </p>
            </div>

            <div className="ClientsestimonialCardsSwiper">
              <Swiper
                modules={[Autoplay, Pagination]}
                spaceBetween={13}
                slidesPerView={isDesktopOrLaptop ? 2.7 : isTablet ? 1 : isMobile ? 1 : 1}
                autoplay={{ delay: 3500, disableOnInteraction: true }}
                className='ClientsTestimonialSwiperParent'
                pagination={{
                  pagination: true,
                  clickable: true,
                }}
                loop={true}
              >

                <SwiperSlide className='ClientTestimonialCard ONGC'>
                  <div className="testimonialCompanyLogo" id='ongc'>
                    <img src={ONGC} alt="" />
                  </div>
                  <h1 className="SmallHeadings">One of the best cleaning robot we have ever used</h1>
                  <p className="universal-paragraphs">
                    Robots exceeded expectations, efficiently cleaning our solar panels, showcasing remarkable performance as one of the finest cleaning robots.
                  </p>
                  <div>
                    <h1 className="universal-paragraphs" style={{ color: '#9F1111', margin: "0", padding: '0' }}>Manoj Sharma, GM & Project Head</h1>
                    <h1 className="universal-paragraphs" style={{ color: '#9F1111', margin: "0", padding: '0' }}>ONGC Jodhpur, Mandore Road</h1>
                  </div>
                </SwiperSlide>

                <SwiperSlide className='ClientTestimonialCard BROOK' >
                  <div className="testimonialCompanyLogo" id='brookfield'>
                    <img src={Brookfeild} alt="" />
                  </div>
                  <h1 className="SmallHeadings">The pilot phase has been met with satisfaction.</h1>
                  <p className="universal-paragraphs">
                    Vysion Greenbots Pvt. Ltd. successfully demonstrated robot for 45-days without any breakdown for ABC Renewable Energy Pvt. Ltd., the robot has effectively and efficiently cleaned our solar panels.
                  </p>
                  <div>
                    <h1 className="universal-paragraphs" style={{ color: '#074A78', margin: "0", padding: '0' }}>Shourabh Roy, Asset Manager</h1>
                    <h1 className="universal-paragraphs" style={{ color: '#074A78', margin: "0", padding: '0' }}>Bhadla Solar Park, Rajasthan</h1>
                  </div>

                </SwiperSlide>

                <SwiperSlide className='ClientTestimonialCard SolarPulse' >
                  <div className="testimonialCompanyLogo" id='solarpulse'>
                    <img src={solarpulse} alt="" />
                  </div>
                  <h1 className="SmallHeadings">We've had a fantastic experience with Vysion Greenbots.</h1>
                  <p className="universal-paragraphs">
                    Vysion Greenbots has significantly improved the efficiency and cleanliness of our solar panels, leading to increased energy output and cost reductions.
                  </p>
                  <div>
                    <h1 className="universal-paragraphs" style={{ color: '#FAA831', margin: "0", padding: '0' }}>Onkar Singh Rajpurohit, Director</h1>
                    <h1 className="universal-paragraphs" style={{ color: '#FAA831', margin: "0", padding: '0' }}>Bhadla Solar Park, Rajasthan</h1>
                  </div>

                </SwiperSlide>

                <SwiperSlide className='ClientTestimonialCard Welspun' >
                  <div className="testimonialCompanyLogo" id='welspun'>
                    <img src={welspun} alt="" />
                  </div>
                  <h1 className="SmallHeadings">Amazing inventions by Vysion Greenbots, pleased to collaborate</h1>
                  <p className="universal-paragraphs">
                    Vysion Greenbots' innovative solutions have significantly improved solar panel maintenance efficiency and environmental impact, resulting in positive outcomes and a positive working relationship.
                  </p>
                  <div>
                    <h1 className="universal-paragraphs" style={{ color: '#009FE3', margin: "0", padding: '0' }}>Ravi Thanvi, GM</h1>
                    <h1 className="universal-paragraphs" style={{ color: '#009FE3', margin: "0", padding: '0' }}>Welspun Solar Plant, Bhuj, Gujarat</h1>
                  </div>

                </SwiperSlide>
              </Swiper>

            </div>
          </div>
          <br />
          <br />
          <br />

          <br />
          <br />
          <br />
        </div>






        <div id="OurNews" className='UniversalInnerContainer'>
          <div className="ClientsTestimonialsWrapper">
            <div className="SectionHeadingandDesc">
              <img src={news} alt="Dhruva robot" />
              <h1 className="LandingHeading">Featured <br className='BreakPoint' />Articles</h1>
              <p className="universal-paragraphs">
                Vysion Technology's core values are innovation, with a strong emphasis on producing exceptional products through extensive research and development.
              </p>
            </div>


            {/* swiperfor news articles */}
            <div className="ClientsestimonialCardsSwiper">
              <Swiper
                modules={[Autoplay, Pagination]}
                spaceBetween={13}
                // slidesPerView={1}
                slidesPerView={isDesktopOrLaptop ? 1.7 : isTablet ? 1 : isMobile ? 1 : 1}
                autoplay={{ delay: 2400, disableOnInteraction: true }}
                className='ClientsTestimonialSwiperParent'
                pagination={{
                  pagination: true,
                  clickable: true,
                }}
                loop={true}
              >

                <SwiperSlide className='ClientTestimonialCard ArticleCardSpecifiedCss'>
                  <div className="testimonialCompanyLogo" id='edtimes'>
                    <img src={edtimes} alt="" />
                  </div>
                  <h1 className="SmallHeadings">This Robot Will Increase 8500MW Solar Energy Production Monthly In India</h1>
                  <p className="universal-paragraphs">
                    Dhruvaan will increase a plant's daily power output by 3.8%, the greatest documented cleaning result thus far. This approach has earned two patents from Vysion technology.
                  </p>
                  <button className="universal-button" onClick={() => { window.open("https://edtimes.in/this-robot-will-increase-approx-8500-mw-solar-energy-production-monthly-in-india/?amp") }}>Read Article</button>
                </SwiperSlide>

                <SwiperSlide className='ClientTestimonialCard ArticleCardSpecifiedCss'>
                  <div className="testimonialCompanyLogo" id='dainik'>
                    <img src={dainikbhaskar} alt="" />
                  </div>
                  <h1 className="SmallHeadings">Dhruvaan, an internet-connected robot developed by Vysion Technology, can clean solar plants without the need of water.</h1>
                  <p className="universal-paragraphs">
                    India is becoming the hub of Solar power Generation, but the biggest challenge for the solar power plants installed areas is to clean the sand accumulated on the solar plants.
                  </p>
                  <button className="universal-button" onClick={() => { window.open("https://edtimes.in/this-robot-will-increase-approx-8500-mw-solar-energy-production-monthly-in-india/?amp") }}>Read Article</button>
                </SwiperSlide>

                {/* <SwiperSlide className='ClientTestimonialCard ArticleCardSpecifiedCss'>
                  <div className="testimonialCompanyLogo" id='welspun'>
                    <img src={welspun} alt="" />
                  </div>
                  <h1 className="SmallHeadings">3</h1>
                  <p className="universal-paragraphs">
                    Robots exceeded expectations, efficiently cleaning our solar panels, showcasing remarkable performance as one of the finest cleaning robots.
                  </p>
                  <button className="universal-button">Read Article</button>
                </SwiperSlide> */}
              </Swiper>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />







        <div id="OurCutouts" className='UniversalInnerContainer'>
          <div className="ClientsTestimonialsWrapper">
            <div className="SectionHeadingandDesc">
              <img src={article} alt="Dhruva robot" />
              <h1 className="LandingHeading">News &<br className='BreakPoint' />Columns</h1>
              <p className="universal-paragraphs">
                Vysion Technology's core values are innovation, with a strong emphasis on producing exceptional products through extensive research and development.
              </p>
            </div>

            {/* news cutouts swiper */}
            <div className="ClientsestimonialCardsSwiper AdditionalCssForNewsCutouts">
              <Swiper
                modules={[Autoplay, Pagination]}
                spaceBetween={15}
                // slidesPerView={1}
                slidesPerView={isDesktopOrLaptop ? 5 : isTablet ? 3 : isMobile ? 3 : 3}
                autoplay={{ delay: 2100, disableOnInteraction: true }}
                className='ClientsTestimonialSwiperParent'
                loop={true}
              >
                <SwiperSlide className='newsCard' style={{ background: `url(${two}) no-repeat center/cover` }} onClick={() => { ViewImage(cutout1) }}></SwiperSlide>
                <SwiperSlide className='newsCard' style={{ background: `url(${one}) no-repeat center/cover` }} onClick={() => { ViewImage(cutout2) }}></SwiperSlide>
                <SwiperSlide className='newsCard' style={{ background: `url(${three}) no-repeat center/cover` }} onClick={() => { ViewImage(cutout3) }}></SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>

        <br />
        <br />
        <br />

        <div id="OurPatents" className='UniversalInnerContainer'>
          <div className="ClientsTestimonialsWrapper">
            <div className="SectionHeadingandDesc">
              <img src={patent} alt="Dhruva robot" />
              <h1 className="LandingHeading">Patents &<br className='BreakPoint' />Certification</h1>
              <p className="universal-paragraphs">
                Vysion Technology's core values are innovation, with a strong emphasis on producing exceptional products through extensive research and development.
              </p>
            </div>

            {/* ptent swiper */}
            <div className="ClientsestimonialCardsSwiper PatentSwiperAdditionalCss">
              <Swiper
                modules={[Autoplay, Pagination]}
                spaceBetween={10}
                // slidesPerView={1}
                slidesPerView={isDesktopOrLaptop ? 4 : isTablet ? 3 : isMobile ? 2 : 2}
                autoplay={{ delay: 2100, disableOnInteraction: true }}
                className='ClientsTestimonialSwiperParent'
              // loop={true}
              >
                <SwiperSlide className='PatentCard' style={{ background: `url(${FrameOne}) no-repeat center/cover` }}></SwiperSlide>
                <SwiperSlide className='PatentCard' style={{ background: `url(${FrameThree}) no-repeat center/cover` }}></SwiperSlide>
                <SwiperSlide className='PatentCard' style={{ background: `url(${FrameTwo}) no-repeat center/cover` }}></SwiperSlide>
                <SwiperSlide className='PatentCard' style={{ background: `url(${FrameFive}) no-repeat center/cover` }}></SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>

        <br />
        <br />
        <br />
        <br />

        <Contact />
        <br />
        <br />

        <Footer />
      </div>
    </>
    )
  }
}
