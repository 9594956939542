import React from "react";
import './ComponentsStyle/Deviceparts.css'
import { Parallax, ParallaxBanner } from "react-scroll-parallax";
import pot from '../images/pot.png'
import sq from '../images/sq.png'
import { Link, useNavigate } from "react-router-dom";
import { CollageTileThree, CollageTileTwo, CollageTileVideoOne, CollageTileVideoTwo, DhruvaanCurrentVideo } from "./Assets";

export default function DeviceParts(props) {

  const navigate = useNavigate()
  return (
    <section className="backcol" id="features">
      <div className="product_information_wrapper">
        <div className="maxWidthContainer">
          <Parallax className="content_container content_of_leftSide">
            {/* <div className="content_container content_of_leftSide"> */}
            <h1 className="LandingHeading">Dhruvaan <span style={{ color: 'var(--PrimaryGreen)' }}>D-2</span></h1>
            <p className="universal-paragraphs">
              Experience efficient and hassle-free solar panel cleaning with <b>Dhruvaan</b>. Our innovative robot is specifically designed to keep your solar panels spotless, ensuring optimal performance and maximum energy output.
            </p>
            {/* <br /> */}
            <Link className="universal-button" to="/dhruvaan" state={{ scrollTarget: "DetailedDhruvaanHome"}}>Explore</Link>
            {/* </div> */}
          </Parallax>
          <div className="grid_container">
            <div className="GridParent">
              <ParallaxBanner className="one">
                <video loop autoPlay muted playsInline>
                  <source src={CollageTileVideoOne} />
                </video>
              </ParallaxBanner>
              <ParallaxBanner layers={[{ image: pot, speed: -15 }]} className="two"></ParallaxBanner>
              <ParallaxBanner layers={[{ image: CollageTileThree, speed: 4 }]} className="three"></ParallaxBanner>
              <ParallaxBanner layers={[{ image: CollageTileTwo, speed: -6 }]} className="four"></ParallaxBanner>
              <ParallaxBanner className="five">
                <video loop autoPlay muted playsInline>
                  <source src={CollageTileVideoTwo} />
                </video>
              </ParallaxBanner>
              <ParallaxBanner className="six" onClick={()=>{window.open(DhruvaanCurrentVideo)}}>
                <h1 className="SmallHeadings">Watch&nbsp; Dhruvaan&nbsp; in&nbsp; Action&nbsp;</h1>
                <div className="icon">
                  <i class="fas fa-long-arrow-alt-right" style={{ color: '#ffffff' }}></i>
                </div>
              </ParallaxBanner>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
}
