import React, { useEffect, useRef, useState } from 'react';
import companyLogo from "../images/companyLogo.png";
import './ComponentsStyle/Navbar.css'
import { Link } from 'react-router-dom';
import vysion from '../images/Vysion24x24.svg'
import dhruvaan from '../images/Dhruvaan24x24.svg'
import { BookDemo, ContactNumber, DhruvaanMobileApp, DhruvaanWebApp, Facebook, Instagram, Linkedin, Twitter, YouTube } from './Assets';


export default function Navbar(props) {

  const [SP, setSP] = useState(0)

  // making navbar transparent on homescreen
  window.onscroll = function () {
    var currentScrollPos = window.pageYOffset;
    setSP(currentScrollPos)
    if (currentScrollPos < 100 && props.isHome) { setActive(0) }
    else { setActive(1) }
  }

  useEffect(() => {
    if (SP < 100 && props.isHome) { setActive(0) }
    else { setActive(1) }
  }, [])



  const [hamMenu, sethamMenu] = useState(false);
  const [Active, setActive] = useState(1);


  // for closing drawer on click outside anywhere
  const drawerRef = useRef(null)
  useEffect(() => {
    const handleOutsideClick = (event) => { if (hamMenu && drawerRef.current && !drawerRef.current.contains(event.target)) { sethamMenu(false) } }
    document.addEventListener('click', handleOutsideClick, true);
    return () => { document.removeEventListener('click', handleOutsideClick, true) }
  }, [hamMenu]);


  return (
    <div className="navbar-base" id='navbar' style={{
      boxShadow: Active === 1 ? "0px 2px 10px 0px rgba(0, 0, 0, 0.06)" : "none",
      backgroundColor: Active === 1 ? "white" : "transparent"
    }}>
      <div className="navbar-wrapper"
        // custom stylings for navbar background
        style={{
          backgroundColor: Active === 0 ? "transparent" : "white"
        }}>
        <div className="company-logo-container" >

          {/* video for when user hover on logo, the gif will display on other pages. */}
          {
            Active !== 0 ? <Link to="/"><img
              src={"https://ik.imagekit.io/hgfugmm0dbi/Dhruvaan%20New%20Website/Untitled%20design_2YDR6uGtN.gif?updatedAt=1698996758787"}
              alt="logo"
              // id='desktopLogo'
              className='OnHoverLogoAnimation'
            /></Link> : ""
          }

          {/* a backup logo for when user hovers on homepage, the logo will not disapper. */}
          {
            Active === 0 ? <Link to="/"><img
              src={companyLogo}
              alt="logo"
              className='BackupLogo'
              style={{
                filter: Active === 0 ? "invert(0)" : "invert(1)"
              }}
            /></Link> : ""
          }
          {/* MAin logo for navbar */}
          <Link to="/"><img
            src={companyLogo}
            alt="logo"
            id='desktopLogo'
            style={{
              filter: Active === 0 ? "invert(0)" : "invert(1)"
            }}
          /></Link>
        </div>

        <div className="website-links" id='he'
          style={{
            color: Active === 0 ? "white" : "black"
          }}
        >
          <Link to="/" className="link-container" state={{ scrollTarget: "home" }} >
            Home
          </Link>

          <Link to="/about" className="link-container" id='SubNavAbout' state={{ scrollTarget: "DetailedAboutHome" }}>
            About us
            <div className="SubLinkMenu" id='SubNavAboutOpen'>
              <NavSubLink title={"Vysion Greenbots"} icon={"none"} image={vysion} target={"/about"} ScrollTo={"AboutCompany"} />
              <NavSubLink title={"Mission & Vision"} icon={"fas fa-bullseye"} target={"/about"} ScrollTo={"MissionVision"} />
              <NavSubLink title={"Our Team"} icon={"fas fa-users fa-sm"} target={"/about"} ScrollTo={"OurTeam"} />
            </div>
          </Link>

          <Link to="/dhruvaan" state={{ scrollTarget: "DetailedDhruvaanHome" }} className="link-container">Dhruvaan</Link>

          <Link to="/benefits" state={{ PlantCap: 100, scrollTarget: "DetailedBenefitTop" }} className="link-container">Benefits</Link>

          <Link to="/" state={{ scrollTarget: "application" }} className="link-container" id='SubNavSoftware'>
            Software
            <div className="SubLinkMenu" id='SubNavSoftwareOpen'>
              <NavSubLink title={"Web Application"} icon={"fas fa-desktop"} target={"noRoute"} link={DhruvaanWebApp} />
              <NavSubLink title={"Mobile App"} icon={"fas fa-mobile-alt"} target={"noRoute"} link={DhruvaanMobileApp} />
              <NavSubLink title={"Features"} icon={'fas fa-list-ul'} target={"/"} ScrollTo={"application"} />
            </div>
          </Link>

          <Link to="/clients" state={{ scrollTarget: "ClientsHome" }} className="link-container" id='SubNavClients'>
            Clients
            <div className="SubLinkMenu" id='SubNavClientsOpen'>
              <NavSubLink title={"Clients"} icon={"far fa-handshake"} target={"/clients"} ScrollTo={"OurClientsTestimonials"} />
              <NavSubLink title={"News"} icon={"fas fa-newspaper"} target={"/clients"} ScrollTo={"OurNews"} />
              <NavSubLink title={"Patents"} icon={"fas fa-award"} target={"/clients"} ScrollTo={"OurPatents"} />
            </div>
          </Link>

          <Link to="/" state={{ scrollTarget: "ContactHome" }} className="link-container" id='SubNavContact'>
            Contact
            <div className="SubLinkMenu" id='SubNavContactOpen'>
              <NavSubLink title={"Call Us"} icon={"fas fa-phone-alt"} target={"noRoute"} link={`tel:${ContactNumber}`} />
              <NavSubLink title={"Book a demo"} icon={"fas fa-chalkboard-teacher"} target={"noRoute"} link={BookDemo} />
              <NavSubLink title={"Send a querry"} icon={"fas fa-question-circle"} target={"/"} ScrollTo={"FooterHome"} />


              <div className='SubLink' id='SubNavSocial'>
                <Link className='dFlex' id='SubNavSocial'>
                  <div className="NavSubLinksIcons"> <i className="fas fa-hashtag"></i></div>
                  Social media
                  <div className="SubSubLinkMenu" id='SubNavSocialOpen'>
                    <NavSubLink title={"Linkedin"} icon={"fab fa-linkedin-in"} target={"noRoute"} link={Linkedin} />
                    <NavSubLink title={"YouTube"} icon={"fab fa-youtube"} target={"noRoute"} link={YouTube} />
                    <NavSubLink title={"Twitter"} icon={"fab fa-twitter"} target={"noRoute"} link={Twitter} />
                    <NavSubLink title={"Instagram"} icon={"fab fa-instagram"} target={"noRoute"} link={Instagram} />
                    <NavSubLink title={"Facebook"} icon={"fab fa-facebook-f"} target={"noRoute"} link={Facebook} />
                  </div>
                </Link>
              </div>
            </div>
          </Link>

        </div>




        <div className="navbar_social_Container">
          {/* <div className="call"></div> */}
          {/* <div className="call"></div> */}
          {/* <div className="call"></div> */}
        </div>



        <div className="hamburger-menu" id='ham' style={{ color: Active === 0 ? "white" : "black" }} ref={drawerRef}>
          <i className="fas fa-bars" onClick={() => { sethamMenu(true) }}></i>
          <div className="hamMenu-items" style={{ right: hamMenu ? "0px" : "-300px" }}>

            <DrawerOption Name={"Home"} route={'/'} scrollTarget={"home"} DropDownType={false} icon={"fas fa-home fa-sm"} sethamMenu={sethamMenu} />
            <DropDownOption
              SelectorName={"About us"}
              SelectorIcon={"none"}
              SelectorImage={vysion}
              sethamMenu={sethamMenu}
              selections={[
                {
                  icon: "none",
                  image: vysion,
                  route: "/about",
                  scrollTarget: "AboutCompany",
                  Name: "About Company"
                },
                {
                  icon: "fas fa-bullseye fa-sm",
                  route: '/about',
                  Name: "Mission & Vision",
                  scrollTarget: "MissionVision",
                },
                {
                  icon: "fas fa-users fa-xs",
                  route: '/about',
                  Name: "Our team",
                  scrollTarget: "OurTeam"
                },
              ]}
            />

            <DrawerOption Name={"Dhruvaan"} route={'/dhruvaan'} scrollTarget={"DetailedDhruvaanHome"} DropDownType={false} icon={"none"} image={dhruvaan} sethamMenu={sethamMenu} />
            <DrawerOption Name={"Benefits"} route={'/benefits'} scrollTarget={"DetailedBenefitTop"} DropDownType={false} icon={"fas fa-hand-holding-usd fa-sm"} sethamMenu={sethamMenu} />

            <DropDownOption
              SelectorName={"Software"}
              SelectorIcon={"fas fa-mobile-alt fa-sm"}
              sethamMenu={sethamMenu}
              selections={[
                {
                  icon: "fas fa-desktop fa-xs",
                  route: "noRoute",
                  link: DhruvaanWebApp,
                  Name: "Web Application",
                },
                {
                  icon: "fas fa-mobile-alt fa-sm",
                  route: 'noRoute',
                  link: DhruvaanMobileApp,
                  Name: "Mobile Application"
                },
                {
                  icon: "fas fa-list-ul fa-xs",
                  route: '/',
                  scrollTarget: "application",
                  Name: "Features"
                },
              ]}
            />

            <DropDownOption
              SelectorName={"Clients"}
              SelectorIcon={"far fa-handshake fa-sm"}
              sethamMenu={sethamMenu}
              selections={[
                {
                  icon: "far fa-handshake fa-sm",
                  route: "/clients",
                  Name: "Clients",
                  scrollTarget: 'OurClientsTestimonials'
                },
                {
                  icon: "fas fa-newspaper fa-sm",
                  route: '/clients',
                  Name: "News",
                  scrollTarget: 'OurNews'
                },
                {
                  icon: "fas fa-award fa-sm",
                  route: '/clients',
                  Name: "Patents",
                  scrollTarget: 'OurPatents'
                },
              ]}
            />

            <DropDownOption
              SelectorName={"Contact"}
              SelectorIcon={"fas fa-headset fa-sm"}
              sethamMenu={sethamMenu}
              selections={[
                {
                  icon: "fas fa-phone-alt fa-xs",
                  route: "noRoute",
                  link: `tel:${ContactNumber}`,
                  Name: "Call us",

                },
                {
                  icon: "fas fa-chalkboard-teacher fa-xs",
                  route: "noRoute",
                  link: BookDemo,
                  Name: "Book a demo",

                },
                {
                  icon: "fas fa-question-circle fa-sm",
                  route: '/',
                  scrollTarget: 'FooterHome',
                  Name: "Send a query"
                },
              ]}
            />

            <DropDownOption
              SelectorName={"Social Media Handles"}
              SelectorIcon={"fas fa-hashtag fa-sm"}
              sethamMenu={sethamMenu}
              selections={[
                {
                  icon: 'fab fa-linkedin-in fa-sm',
                  route: "noRoute",
                  link: Linkedin,
                  Name: "LinkedIn"
                },
                {
                  icon: "fab fa-youtube fa-sm",
                  route: 'noRoute',
                  link: Twitter,
                  Name: "Twitter"
                },
                {
                  icon: "fab fa-twitter fa-sm",
                  route: 'noRoute',
                  link: YouTube,
                  Name: "YouTube"
                },
                {
                  icon: "fab fa-instagram fa-sm",
                  route: 'noRoute',
                  link: Instagram,
                  Name: "Instagram"
                },
                {
                  icon: "fab fa-facebook-f fa-sm",
                  route: 'noRoute',
                  link: Facebook,
                  Name: "Facebook"
                },
              ]}
            />
            {/* <div className="callUsButtonWrapper"><button className='universal-button'>Call now</button></div> */}
          </div>
        </div>
      </div>
    </div>
  )
}


// Link component for desktop devices with all passing data
function NavSubLink(props) {
  return props.target === "noRoute" ? //Checking if this link is a route or an external link. 
    <div className="SubLink" onClick={() => { window.open(props.link) }}>
      <div className='dFlex'>
        <div className="NavSubLinksIcons">
          {props.icon === "none" ? <img src={props.image} alt='greenbots' /> : <i className={props.icon}></i>}
        </div>
        {props.title}
      </div>
    </div>
    :
    <div className="SubLink">
      <Link to={props.target} state={{ scrollTarget: props.ScrollTo }} className='dFlex'>
        <div className="NavSubLinksIcons">
          {props.icon === "none" ? <img src={props.image} alt='vysion greenbots' /> : <i className={props.icon}></i>}
        </div>
        {props.title}
      </Link>
    </div>
}






// This component represents a dropdown option that can expand and collapse based on user interaction.
function DropDownOption(props) {
  // Ref to track the outside click to close the dropdown.
  const closeRef = useRef(null);
  // Calculate dynamic height of the dropdown based on the number of selections.
  const DynamicHeight = `${50 * (props.selections.length + 1)}px`;
  // State to manage the active/collapsed state of the dropdown.
  const [DropDownActive, setDropDownActive] = useState(false);

  // Effect to close the dropdown when clicking outside of it.
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (DropDownActive && closeRef.current && !closeRef.current.contains(event.target)) {
        setDropDownActive(false);
      }
    };
    document.addEventListener('click', handleOutsideClick, true);
    return () => {
      document.removeEventListener('click', handleOutsideClick, true);
    };
  }, [DropDownActive]);

  // Render the dropdown option and its content.
  return (
    <div
      className='DropdownBase'
      style={{
        height: DropDownActive ? DynamicHeight : '50px',
        minHeight: DropDownActive ? DynamicHeight : '50px',
        borderLeft: DropDownActive ? '3px solid var(--PrimaryGreen)' : '3px solid transparent',
      }}
      ref={closeRef}
    >
      <div className="option" onClick={() => { setDropDownActive(!DropDownActive) }}>
        <div className="dFlex">
          <div className="NavSubLinksIcons">
            {/* Display an image or icon based on the props */}
            {props.SelectorIcon === "none" ? <img src={props.SelectorImage} alt='dhruv' /> : <i className={props.SelectorIcon}></i>}
          </div>
          {/* Display the selector name */}
          {props.SelectorName}&nbsp;&nbsp;&nbsp;
          {/* Display an arrow icon that rotates when dropdown is active */}
          <i
            className="far fa-angle-down"
            style={{ transform: DropDownActive ? 'rotate(180deg)' : 'rotate(0deg)', transition: '0.3s' }}
          ></i>
        </div>
      </div>
      {/* Render dropdown options if it's active */}
      {DropDownActive ? (
        props.selections.map((selector) => {
          return (
            <DrawerOption
              icon={selector.icon}
              route={selector.route}
              scrollTarget={selector.scrollTarget}
              link={selector.link}
              Name={selector.Name}
              image={selector.image}
              DropDownType={true}
              sethamMenu={props.sethamMenu}
            />
          );
        })
      ) : (
        ''
      )}
    </div>
  );
}



function DrawerOption(props) {
  return props.route === "noRoute" ? <div
    className="option"
    onClick={() => { props.sethamMenu(false) }}
    style={{
      transform: props.DropDownType ? "translateX(20px)" : "translateX(0px)", //Shifting dropdown items to a little right
      borderLeft: '3px solid transparent' // Transparent border for alignment with other option green border
    }}>
    <div className='dFlex' onClick={() => { window.open(props.link) }}>
      <div className="NavSubLinksIcons">
        {props.icon === "none" ? <img src={props.image} alt='solar clean' /> : <i className={props.icon}></i>}
      </div>
      {props.Name}
    </div>
  </div>
    :
    <div
      className="option"
      onClick={() => { props.sethamMenu(false) }}
      style={{
        transform: props.DropDownType ? "translateX(20px)" : "translateX(0px)", //Shifting dropdown items to a little right
        borderLeft: '3px solid transparent' // Transparent border for alignment with other option green border
      }}>
      <Link to={props.route} state={{ scrollTarget: props.scrollTarget, PlantCap: 100 }} className='dFlex'>
        <div className="NavSubLinksIcons">
          {props.icon === "none" ? <img src={props.image} alt='dhruvaan greenbot vision' /> : <i className={props.icon}></i>}
        </div>
        {props.Name}
      </Link>
    </div>
}


